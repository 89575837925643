const TextField = ({
  label,
  placeholder,
  type = "text",
  value,
  onChange,
  colSpan = 2,
  required = false,
  readOnly = false,
  min,
  step,
  extraClassName,
}) => {
  return (
    <div
      className={`mb-2 col-span-2 ${
        colSpan != 2 ? "sm:col-span-1" : "sm:col-span-2"
      }`}
    >
      <label className="block text-md font-light leading-6 text-gray-900">
        {label}
      </label>
      <div className="mt-2">
        <input
          onChange={onChange}
          value={value}
          type={type}
          placeholder={placeholder}
          className={`block w-full placeholder:font-light rounded-md border-0 p-3.5 bg-whiteSmoke text-gray-900 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6 ${extraClassName}`}
          required={required}
          readOnly={readOnly}
          disabled={readOnly}
          min={min}
          step={step}
        />
      </div>
    </div>
  );
};

export default TextField;
