import React, { useContext, useEffect, useState } from "react"
import {getOpenOrdersAPI} from  "../../../api/order_api"
import { userDataContext } from "../../../context/userDataContext";
import TextField from "../../../components/form/TextField";

import {ReactComponent as DownArrowPointer} from "../../../assets/icons/DownArrowPointer.svg"
import {ReactComponent as UpArrowPointer} from "../../../assets/icons/UpArrowPointer.svg"

function convertOrderTypeToIcon(orderType){
    switch(orderType){
        case 1:
            return (<DownArrowPointer />)
        case 7:
            return (<UpArrowPointer/>)
        default:
            return `Annet`
    }
}
function convertOrderTypeToText(orderType){
    switch(orderType){
        case 1:
            return (`PO`)
        case 7:
            return (`SO`)
        default:
            return `Annet`
    }
}

const ActiveOrdersSection = ()=> {
    // contexts
    const { userDataState } = useContext(userDataContext);
    // states
    const [openOrders, setOpenOrders] = useState([])

    const getActiveOrders = async ()=> {
        let res = await getOpenOrdersAPI(userDataState.accessToken, {order_type: 1});
        console.log(res)
        setOpenOrders(res)
        return res
    }
    useEffect(()=>{
        getActiveOrders()
    },[]);
    

    return (
        <>
        {/* <div className="grid grid-cols-1 gap-4 border-[1px] border-deepSkyBlue rounded-2xl p-10"> */}
            {/* <p className="col-span-full text-deepSkyBlue text-2xl mb-4">
                Aktive ordre
            </p> */}
            <TextField
                placeholder="Søk etter ordrenummer, kunde eller fartøy..."
                extraClassName="uppercase"
            />
            <div className="relative overflow-x-auto rounded-2xl border-[1px]">
            <table className="w-full text-sm text-left">
                <thead className="text-xs uppercase bg-gray-100">
                <tr>
                    <th scope="col" className="px-6 py-3">Ordre</th>
                    <th scope="col" className="px-6 py-3">Kunde/Leverandør</th>
                    <th scope="col" className="px-6 py-3">Fartøy</th>
                    <th scope="col" className="px-6 py-3">Leveres fra</th>
                    <th scope="col" className="px-6 py-3">Leveringsdato</th>
                    <th scope="col" className="px-6 py-3">Leveringssted</th>
                    <th scope="col" className="px-6 py-3">Kundens PO/SO</th>
                    <th scope="col" className="px-6 py-3">Valuta</th>
                </tr>
                </thead>
                <tbody>
                {openOrders.map((order)=>{return (
                    <tr className="bg-white border-b hover:bg-deepSkyBlue hover:text-white" key={order.order_id}>
                        <th scope="row" className="px-6 py-4 font-bold whitespace-nowrap text">{`${convertOrderTypeToText(order.order_type)}${order.order_id}`}</th>
                        <td className="px-6 py-4">{order.order_type==1 ? order.supplier?.name : order.customer?.name}</td>
                        <td className="px-6 py-4">{order?.information_6}</td>
                        <td className="px-6 py-4">{order?.from_warehouse.name}</td>
                        <td className="px-6 py-4">{order?.delivery_date}</td>
                        <td className="px-6 py-4">{order?.information_5}</td>
                        <td className="px-6 py-4">{order?.customer_or_supplier_order_number}</td>
                        <td className="px-6 py-4">{order.currency.currency_iso}</td>
                </tr>)})}
                </tbody>
            </table>
            </div>
        
        </>
    )
}

export default ActiveOrdersSection