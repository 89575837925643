export async function sendMessageToSlack(message, tenant, channel) {
  var raw = JSON.stringify({
    type: "mrkdwn",
    text: message,
  });
  await fetch(`https://hooks.slack.com/services/${tenant}/${channel}`, {
    mode: "no-cors",
    method: "POST",
    body: raw,
    headers: {
      mode: "no-cors",
      "Content-type": "application/json",
    },
    redirect: "follow",
  })
    .then((response) => response.text())
    .then((result) => console.log(result))
    .catch((error) => console.log("error", error));
}
