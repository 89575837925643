import React, { useState, useEffect, useContext } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Riple } from "react-loading-indicators";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";

import { customApiToken } from "../authConfig";
import LoginPage from "../pages/login/LoginPage";
import { getUserDataAPI, getCommonsAPI } from "../api/common_api";
import { commonAPIContext } from "../context/commonAPIContext";
import { userDataContext } from "../context/userDataContext";
import { SALE_ACTIONS, saleOrderContext } from "../context/saleOrderContext";
import {
  PURCHASE_ACTIONS,
  purchaseOrderContext,
} from "../context/purchaseOrderContext";

import PageHeader from "../components/layout/PageHeader";
import SaleOrderPage from "../pages/sale_order/SaleOrderPage";
import PurchaseOrderPage from "../pages/purchase_order/PurchaseOrderPage";
import TradingPage from "../pages/trading/TradingPage";
import OrdersPage from "../pages/orders/OrdersPage";

import "../input.css";
import "../style/common.css";


export default function App() {
  return (
    <div className="App">
      <AuthenticatedTemplate>
        <Routing />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <LoginPage />
      </UnauthenticatedTemplate>
    </div>
  );
}

function Routing() {
  const { dispatchUserData } = useContext(userDataContext);
  const { dispatchCommonAPI } = useContext(commonAPIContext);
  const { dispatchSaleOrder } = useContext(saleOrderContext);
  const { dispatchPurchaseOrder } = useContext(purchaseOrderContext);

  const [isLoading, setIsLoading] = useState(true);
  const { instance, accounts } = useMsal();

  useEffect(() => {
    async function fetchData() {
      const response = await instance.acquireTokenSilent({
        ...customApiToken,
        account: accounts[0],
      });

      const commons = await getCommonsAPI(response.accessToken);
      const userData = await getUserDataAPI(
        response.account.username,
        response.accessToken
      );

      if (!commons || !userData) {
        fetchData();
      } else {
        // set common data
        dispatchCommonAPI({
          currencies: commons.currencies,
          paymentTerms: commons.payment_terms,
          taxCodes: commons.tax_codes,
          exemptions: commons.excemptions,
          warehouses: commons.warehouses,
          deliveryMethods: commons.delivery_methods.map((delivery_method) => ({
            value: delivery_method,
            title: delivery_method.text,
            subTitle: "",
          })),
          exemptFromSpecialTax: commons.exempt_from_special_tax
        });
        // set user microsoft data
        dispatchUserData(response);
        // set employee id
        dispatchSaleOrder({
          type: SALE_ACTIONS.EMPLOYEE_ID,
          data: userData?.employee_number || "",
        });
        dispatchPurchaseOrder({
          type: PURCHASE_ACTIONS.EMPLOYEE_ID,
          data: userData?.employee_number || "",
        });
        // set microsoft account name
        // dispatchSaleOrder({
        //   type: SALE_ACTIONS.OUR_REFERENCE,
        //   data: response.account.name,
        // });
        // dispatchPurchaseOrder({
        //   type: PURCHASE_ACTIONS.OUR_REFERENCE,
        //   data: response.account.name,
        // });
        // set microsoft account user id
        dispatchSaleOrder({
          type: SALE_ACTIONS.USER_ID,
          data: response.account.localAccountId,
        });
        dispatchPurchaseOrder({
          type: PURCHASE_ACTIONS.USER_ID,
          data: response.account.localAccountId,
        });
        // set microsoft account email
        dispatchSaleOrder({
          type: SALE_ACTIONS.USER_EMAIL,
          data: response.account.username,
        });
        dispatchPurchaseOrder({
          type: PURCHASE_ACTIONS.USER_EMAIL,
          data: response.account.username
        });
        setIsLoading(false);
      }
    }

    fetchData();
  }, [dispatchCommonAPI, dispatchUserData]);

  if (isLoading) {
    return (
      <div className="loading-indicator ">
        <Riple color="#04c3ff" size="medium" text="" textColor="" />
      </div>
    );
  }

  return (
    <BrowserRouter>
      <PageHeader />
      <Routes>
        <Route path="/" element={<SaleOrderPage />} />
        <Route path="/purchase" element={<PurchaseOrderPage />} />
        <Route path="/trading" element={<TradingPage />} />
        <Route path="/orders" element={<OrdersPage />} />
      </Routes>
    </BrowserRouter>
  );
}
