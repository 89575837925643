import React, { createContext, useReducer } from "react";
import { v4 } from "uuid";

// create sale order context
export const saleOrderContext = createContext();

// create function for reducer
const saleOrderReducer = (state, action) => {
  switch (action.type) {
    case SALE_ACTIONS.SET_TRADE_DEFAULT_VALUE:
      state.order_object.order_type = 1;
      state.order_object.group_6 = 8;
      state.order_object.transaction_type = 1;
      return { ...state };
    case SALE_ACTIONS.CUSTOMER_DETAILS:
      // set customer info
      state.customer_details = action.data;
      // set customer id for order object
      state.order_object.customer_number = action.data.customerId;
      // set default value for paymentTermId & currencyId
      if (action.data.paymentTermId) {
        state.order_object.payment_terms = action.data.paymentTermId;
      }
      state.order_object.currency_number = action.currency_number;
      return { ...state };
    case SALE_ACTIONS.ADD_PRODUCT_LINE:
      state.product_lines = [...state.product_lines, action.data];
      return { ...state };
    case SALE_ACTIONS.UPDATE_PRODUCT_LINE:
      state.product_lines[action.data.index] = action.data.productLine;
      return { ...state };
    case SALE_ACTIONS.DELETE_PRODUCT_LINE:
      state.product_lines.splice(action.data, 1);
      return { ...state };
    case SALE_ACTIONS.DOMESTIC_AMOUNT:
      state.domestic_amount = action.data;
      return { ...state };
    case SALE_ACTIONS.USER_ID:
      state.user_id = action.data;
      return { ...state };
    case SALE_ACTIONS.USER_EMAIL:
      state.user_email = action.data;
    // RELATE SALE
    case SALE_ACTIONS.OUR_REFERENCE:
      // state.order_object.our_reference = action.data.toUpperCase();
      return { ...state };
    case SALE_ACTIONS.YOUR_REFERENCE:
      state.order_object.your_reference = action.data.toUpperCase();
      return { ...state };
    case SALE_ACTIONS.EMPLOYEE_ID:
      // set employee id for employee_number and seller_or_buyer
      state.order_object.employee_number = action.data;
      state.order_object.seller_or_buyer = action.data;
      return { ...state };
    case SALE_ACTIONS.PAYMENT_TERMS:
      state.order_object.payment_terms = action.data;
      return { ...state };
    case SALE_ACTIONS.WAREHOUSE:
      // warehouse_details contain planner data also warehouse info
      state.warehouse_details = action.data;
      state.order_object.warehouse_number = action.data.warehouse_id;
      return { ...state };
    case SALE_ACTIONS.DELIVERY_DATE:
      // state.order_object.delivery_date = action.data.toISOString();
      state.order_object.delivery_date = `${action.data.getFullYear()}-${
        action.data.getMonth() + 1 < 10
          ? "0" + (action.data.getMonth() + 1)
          : action.data.getMonth() + 1
      }-${
        action.data.getDate() < 10
          ? "0" + action.data.getDate()
          : action.data.getDate()
      }`;
      // `${action.data.getFullYear()}-${action.data.getMonth()+1<10?'0'+(action.data.getMonth()+1):action.data.getMonth()+1}-${action.data.getDate()<10?'0'+action.data.getDate():action.data.getDate()}`
      return { ...state };
    case SALE_ACTIONS.CURRENCY_NUMBER:
      state.order_object.currency_number = action.data;
      return { ...state };
    case SALE_ACTIONS.SUPPLIER_NUMBER:
      state.order_object.supplier_number = action.data;
      return { ...state };
    case SALE_ACTIONS.NOTE:
      state.note = action.data;
      return { ...state };
    case SALE_ACTIONS.TRANSACTION_GROUP_3:
      state.order_object.transaction_group_3 = action.data;
      return { ...state };
    case SALE_ACTIONS.INFORMATION_1:
      state.order_object.information_1 = action.data.toUpperCase();
      return { ...state };
    case SALE_ACTIONS.INFORMATION_5:
      state.order_object.information_5 = action.data.toUpperCase();
      return { ...state };
    case SALE_ACTIONS.INFORMATION_6:
      state.order_object.information_6 = action.data.toUpperCase();
      return { ...state };
    case SALE_ACTIONS.EXEMPT_FROM_SPECIAL_TAX:
      state.order_object.exempt_from_special_tax = action.data;
      return { ...state };
    case SALE_ACTIONS.TAX_CODE:
      state.order_object.tax_code = action.data;
      return { ...state };
    case SALE_ACTIONS.CUSTOMER_OR_SUPPLIER_ORDER_NUMBER:
      state.order_object.customer_or_supplier_order_number =
        action.data.toUpperCase();
      return { ...state };
    // reset form
    case SALE_ACTIONS.RESET_FORM:
      let newState = {
        reset_form_state: !state.reset_form_state,
        domestic_amount: 0,
        document_type: 0,
        product_lines: [
          {
            lineId: v4(),
            productNo: "",
            name: "",
            quantity: 0,
            price: 0,
            unit: "",
            unitType: "",
            description: null,
            discountPercent: 0,
            discountAmountInCurrency: 0,
            purchaseDuplicate: true,
          },
        ],
        order_object: {
          order_type: 7,
          order_date: new Date().toISOString(),
          employee_number: state.order_object.employee_number,
          seller_or_buyer: state.order_object.seller_or_buyer,
          our_reference: state.order_object.our_reference,
          warehouse_number: 1,
          external_order_id: null,
          delivery_date: null,
          currency_number: 47,
          supplier_number: null,
          exempt_from_special_tax: 0,
          tax_code: 3,
          payment_terms: 21,
        },
      };
      return { ...newState };
    default:
      return { ...state };
  }
};

// initial value for reducer
const initialState = {
  reset_form_state: false,
  domestic_amount: 0,
  document_type: 0,
  product_lines: [
    {
      lineId: v4(),
      productNo: "",
      name: "",
      quantity: 0,
      price: 0,
      unit: "",
      unitType: "",
      description: null,
      discountPercent: 0,
      discountAmountInCurrency: 0,
      purchaseDuplicate: true,
    },
  ],
  order_object: {
    order_type: 7,
    order_date: new Date().toISOString(),
    warehouse_number: 1,
    external_order_id: null,
    delivery_date: null,
    currency_number: 47,
    supplier_number: null,
    exempt_from_special_tax: 0,
    tax_code: 3,
    payment_terms: 21,
  },
};

// pass data around tree
const SaleOrderContextProvider = (props) => {
  const [saleOrderState, dispatchSaleOrder] = useReducer(
    saleOrderReducer,
    initialState
  );

  return (
    <saleOrderContext.Provider value={{ saleOrderState, dispatchSaleOrder }}>
      {props.children}
    </saleOrderContext.Provider>
  );
};

export default SaleOrderContextProvider;

export const SALE_ACTIONS = {
  SET_TRADE_DEFAULT_VALUE: "set_trade_default_value",
  CUSTOMER_DETAILS: "customer_details",
  ADD_PRODUCT_LINE: "add_product_line",
  UPDATE_PRODUCT_LINE: "update_product_line",
  DELETE_PRODUCT_LINE: "delete_product_line",
  DOMESTIC_AMOUNT: "domestic_amount",
  USER_ID: "user_id",
  USER_EMAIL: "user_email",
  RESET_FORM: "reset_form",

  // RELATE SALE
  OUR_REFERENCE: "our_reference",
  YOUR_REFERENCE: "your_reference",
  EMPLOYEE_ID: "employee_id",
  PAYMENT_TERMS: "payment_terms",
  WAREHOUSE: "warehouse",
  DELIVERY_DATE: "delivery_date",
  CURRENCY_NUMBER: "currency_number",
  SUPPLIER_NUMBER: "supplier_number",
  TRANSACTION_GROUP_3: "transaction_group_3",
  EXEMPT_FROM_SPECIAL_TAX: "exempt_from_special_tax",
  TAX_CODE: "tax_code",
  INFORMATION_1: "information_1",
  INFORMATION_5: "information_5",
  INFORMATION_6: "information_6",
  NOTE: "note",
  CUSTOMER_OR_SUPPLIER_ORDER_NUMBER: "customer_or_supplier_order_number",
};
