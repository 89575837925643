import React, { useState, useContext, useEffect, useRef } from "react";
import { parseISO } from 'date-fns'
import { Riple } from "react-loading-indicators";

import { commonAPIContext } from "../../../context/commonAPIContext";
import { userDataContext } from "../../../context/userDataContext";
import {
  SALE_ACTIONS,
  saleOrderContext,
} from "../../../context/saleOrderContext";
import {
  PURCHASE_ACTIONS,
  purchaseOrderContext,
} from "../../../context/purchaseOrderContext";
import { PAGE_TYPE } from "../../../utils/enums/pageType";
import {
  getVesselsAPI,
  getCustomersAPI,
  getCustomerAmountDomesticAPI,
} from "../../../api/order_api";
import InfoCard from "../../../components/ui/InfoCard";
import DropDownField from "../../../components/form/DropDownField";
import TextField from "../../../components/form/TextField";
import TextAreaField from "../../../components/form/TextAreaField";
import DatePickerField from "../../../components/form/DatePickerField";
import AsyncSearchDropDownField from "../../../components/form/AsyncSearchDropDownField";

import { ReactComponent as ShipIcon } from "../../../assets/icons/Ship.svg";
import { ReactComponent as ProfileIcon } from "../../../assets/icons/Profile.svg";
import { ReactComponent as CardIcon } from "../../../assets/icons/Card.svg";
import { ReactComponent as MoneyIcon } from "../../../assets/icons/Money.svg";

const SaleOrderHeadingSection = ({ pageType = PAGE_TYPE.SALE }) => {
  // check which page
  const isTradingPageType = pageType == PAGE_TYPE.TRADING;

  // hard code
  const feeExemptionFilter = [6, 12, 21, 22, 23, 26, 30, 60, 99];
  const taxCodesFilter = isTradingPageType ? [3, 4, 5] : [3, 4, 5];

  // contexts
  const { userDataState } = useContext(userDataContext);
  const { commonAPIstate } = useContext(commonAPIContext);
  const { saleOrderState, dispatchSaleOrder } = useContext(saleOrderContext);
  const { purchaseOrderState, dispatchPurchaseOrder } = useContext(purchaseOrderContext);

  const prevStateRef = useRef(saleOrderState);

  const initialCardState = {
    actorId: "",
    vesselName: "",
    customerId: "",
    customerName: "",
    creditLimit: "",
    currencyId: "",
    paymentTermId: "",
  };

  // states
  const [cardValues, setCardValues] = useState(initialCardState);
  const [vesselsOptions, setVesselsOptions] = useState([]);
  const [customersOptions, setCustomersOptions] = useState([]);
  const [amountDomestic, setAmountDomestic] = useState("0");
  const [domesticLoading, setDomesticLoading] = useState(false);
  const [vesselCustomerLoading, setVesselCustomerLoading] = useState(false);
  // just for reset form
  const [selectResetForm, setSelectResetForm] = useState({
    vessel: null,
    customer: null,
  });

  const getVesselsOptions = async (vesselName) => {
    let res = await getVesselsAPI(vesselName, userDataState.accessToken);
    let arr = res.map((vessel) => ({
      value: {
        actorId: vessel.actor_id,
        vesselName: vessel.name,
        customerId: vessel.customer.customer_id,
        customerName: vessel.customer.name,
        creditLimit: vessel.customer.credit_limit,
        currencyId: vessel.customer.currency_id,
        paymentTermId: vessel.customer.payment_term_id,
      },
      title: vessel.name,
      subTitle: vessel.customer.name,
    }));
    setVesselsOptions(arr);

    return arr;
  };

  const getCustomersOptions = async (customerName) => {
    let res = await getCustomersAPI(customerName, userDataState.accessToken);
    let arr = res.map((customer) => ({
      value: {
        actorId: customer.actor_id,
        customerId: customer.customer_id,
        customerName: customer.name,
        creditLimit: customer.credit_limit,
        currencyId: customer.currency_id,
        paymentTermId: customer.payment_term_id,
      },
      title: customer.name,
      subTitle: "",
    }));
    setCustomersOptions(arr);

    return arr;
  };

  const getOptionsWhenPageBuild = async () => {
    if (vesselsOptions) {
      setVesselCustomerLoading(true);
      await getVesselsOptions();
      setVesselCustomerLoading(false);
    }
    if (customersOptions) {
      setVesselCustomerLoading(true);
      await getCustomersOptions();
      setVesselCustomerLoading(false);
    }
  };

  const getAmountDomestic = async (customerId) => {
    setDomesticLoading(true);
    const resAmountDomestic = await getCustomerAmountDomesticAPI(
      customerId,
      userDataState.accessToken
    );
    setAmountDomestic(resAmountDomestic);
    setDomesticLoading(false);

    dispatchSaleOrder({
      type: SALE_ACTIONS.DOMESTIC_AMOUNT,
      data: resAmountDomestic,
    });
  };

  // re again request vessel
  const loadVesselOptions = async (inputValue, callback) => {
    const data = await getVesselsOptions(inputValue);
    callback(data);
  };

  const loadCustomerOptions = async (inputValue, callback) => {
    const data = await getCustomersOptions(inputValue);
    callback(data);
  };

  const setDefaultValueWhenPageBuild = () => {
    dispatchSaleOrder({
      type: SALE_ACTIONS.WAREHOUSE,
      data: commonAPIstate.warehouses[0],
    });
  };

  useEffect(() => {
    if (
      prevStateRef.current.reset_form_state !== saleOrderState.reset_form_state
    ) {
      setCardValues(initialCardState);
      setSelectResetForm({
        vessel: null,
        customer: null,
      });
      setAmountDomestic("0");
      prevStateRef.current = saleOrderState;
    }
  }, [saleOrderState.reset_form_state]);

  useEffect(() => {
    setDefaultValueWhenPageBuild();
    getOptionsWhenPageBuild();
  }, []);

  if (vesselCustomerLoading) {
    return (
      <div className="loading-indicator ">
        <Riple color="#04c3ff" size="medium" text="" textColor="" />
      </div>
    );
  }

  return (
    <>
      <div className="grid grid-cols-2 gap-4 border-[1px] border-deepSkyBlue rounded-2xl p-10">
        <p className="col-span-full text-deepSkyBlue text-2xl mb-4">Ordre</p>
        <AsyncSearchDropDownField
          label="Fartøy/Kunde"
          loadOptions={loadVesselOptions}
          options={vesselsOptions}
          value={selectResetForm.vessel}
          colSpan={isTradingPageType ? 2 : 1}
          required={false}
          onChange={(data) => {
            setCardValues(data.value);
            setSelectResetForm({ vessel: data, customer: null });
            getAmountDomestic(data.value.customerId);
            dispatchSaleOrder({
              type: SALE_ACTIONS.CUSTOMER_DETAILS,
              data: { picker_type: "vessel", ...data.value },
              currency_number: data.value.currencyId
                ? data.value.currencyId
                : 47,
            });
            // reset information 6
            dispatchSaleOrder({
              type: SALE_ACTIONS.INFORMATION_6,
              data: '',
            });
            if (isTradingPageType) {
              dispatchPurchaseOrder({
                type: PURCHASE_ACTIONS.INFORMATION_6,
                data: '',
              });
            }
          }}
        />
        <AsyncSearchDropDownField
          label="Kunde"
          loadOptions={loadCustomerOptions}
          placeholder="Søk etter kunde..."
          options={customersOptions}
          value={selectResetForm.customer}
          colSpan={isTradingPageType ? 2 : 1}
          required={false}
          onChange={(data) => {
            setCardValues(data.value);
            setSelectResetForm({ vessel: null, customer: data });
            getAmountDomestic(data.value.customerId);
            dispatchSaleOrder({
              type: SALE_ACTIONS.CUSTOMER_DETAILS,
              data: { picker_type: "customer", ...data.value },
              currency_number: data.value.currencyId
                ? data.value.currencyId
                : 47,
            });
          }}
        />
        {selectResetForm.customer && (<TextField
          label="Fartøy"
          placeholder="Sett inn Fartøy"
          extraClassName="uppercase"
          onChange={(e) => {
            dispatchSaleOrder({
              type: SALE_ACTIONS.INFORMATION_6,
              data: e.target.value,
            });
            if (isTradingPageType) {
              dispatchPurchaseOrder({
                type: PURCHASE_ACTIONS.INFORMATION_6,
                data: e.target.value,
              });
            }
          }}
        />)}
        <InfoCard
          label="Fartøy"
          data={cardValues.vesselName || saleOrderState.order_object.information_6}
          icon={<ShipIcon />}
        />
        <InfoCard
          label="Kunde"
          data={cardValues.customerName}
          icon={<ProfileIcon />}
        />
        <InfoCard
          label="Kredittgrense"
          label2="Utestående"
          data={cardValues?.creditLimit?.toLocaleString("nb-NO") || ""}
          data2={
            domesticLoading
              ? "Loading.."
              : amountDomestic.toLocaleString("nb-NO", {
                maximumFractionDigits: 0,
              }) || "0"
          }
          icon={<CardIcon />}
        />
        <InfoCard
          label="Tilgjengelig ramme"
          data={
            (cardValues?.creditLimit - amountDomestic).toLocaleString("nb-NO", {
              maximumFractionDigits: 0,
            }) || ""
          }
          icon={<MoneyIcon />}
        />
        <DropDownField
          label="Betalingsbetingelse"
          value={cardValues.paymentTermId || saleOrderState.order_object.payment_terms}
          required={true}
          options={commonAPIstate.paymentTerms.map((term) => (
            <option key={term.payment_term_id} value={term.payment_term_id}>
              {term.name}
            </option>
          ))}
          onChange={(e) => {
            dispatchSaleOrder({
              type: SALE_ACTIONS.PAYMENT_TERMS,
              data: e.target.value,
            });
            setCardValues({ ...cardValues, paymentTermId: e.target.value });
          }}
        />
        {!isTradingPageType && (
          <DropDownField
            label="Leveres fra"
            value={saleOrderState.warehouse_number}
            options={commonAPIstate.warehouses
              .filter((warehouse) => warehouse.warehouse_id != 8)
              .map((warehouse) => (
                <option
                  key={warehouse.id}
                  value={JSON.stringify(warehouse)}
                >
                  {warehouse.name}
                </option>
              ))}
            onChange={(e) => {
              dispatchSaleOrder({
                type: SALE_ACTIONS.WAREHOUSE,
                data: JSON.parse(e.target.value),
              });
            }}
            colSpan={1}
          />
        )}
        <DatePickerField
          label="Leveringsdato"
          value={saleOrderState.order_object.delivery_date ? parseISO(saleOrderState.order_object.delivery_date) : null}
          required={true}
          onChange={(date) => {
            dispatchSaleOrder({
              type: SALE_ACTIONS.DELIVERY_DATE,
              data: date,
            });
          }}
          colSpan={isTradingPageType ? 2 : 1}
        />
        <TextField
          label="Leveringssted"
          placeholder="Sett inn leveringssted"
          extraClassName="uppercase"
          onChange={(e) => {
            dispatchSaleOrder({
              type: SALE_ACTIONS.INFORMATION_5,
              data: e.target.value,
            });
          }}
        />
        <DropDownField
          label="Fritak"
          options={
            <>
              <option key={0} value={null}></option>
              {commonAPIstate.exemptions
                .filter((item) => feeExemptionFilter.includes(item.text_number))
                .map((item) => (
                  <option key={item.text_number} value={item.text_number}>
                    {item.text}
                  </option>
                ))}
            </>
          }
          onChange={(e) => {
            dispatchSaleOrder({
              type: SALE_ACTIONS.TRANSACTION_GROUP_3,
              data: parseInt(e.target.value),
            });
          }}
          colSpan={1}
        />
        <DropDownField
          label="Mva-kode"
          value={saleOrderState.order_object.tax_code}
          options={commonAPIstate.taxCodes
            .filter((item) => taxCodesFilter.includes(item.tax_code_id))
            .map((item) => (
              <option key={item.tax_code_id} value={item.tax_code_id}>
                {item.name}
              </option>
            ))}
          onChange={(e) => {
            dispatchSaleOrder({
              type: SALE_ACTIONS.TAX_CODE,
              data: e.target.value,
            });
          }}
          colSpan={1}
        />
        <DropDownField
          label="Spesialavgiftsfritak"
          options={
            <>
              {commonAPIstate.exemptFromSpecialTax
                .map((item)=> (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
            </>
          }
          onChange={(e) => {
            dispatchSaleOrder({
              type: SALE_ACTIONS.EXEMPT_FROM_SPECIAL_TAX,
              data: parseInt(e.target.value),
            });
          }}
        />
        <TextField
          label="Destinasjon"
          placeholder="Sett inn Destinasjon "
          extraClassName="uppercase"
          onChange={(e) => {
            dispatchSaleOrder({
              type: SALE_ACTIONS.INFORMATION_1,
              data: e.target.value,
            });
          }}
        />
        <TextField
          label="Kundens PO-nummer"
          placeholder="Sett inn Kundens PO-nummer"
          extraClassName="uppercase"
          onChange={(e) => {
            dispatchSaleOrder({
              type: SALE_ACTIONS.CUSTOMER_OR_SUPPLIER_ORDER_NUMBER,
              data: e.target.value,
            });
          }}
        />
        <TextField
          label="Kundens referanseperson"
          placeholder="Sett inn Kundens referanseperson"
          extraClassName="uppercase"
          onChange={(e) => {
            dispatchSaleOrder({
              type: SALE_ACTIONS.YOUR_REFERENCE,
              data: e.target.value,
            });
          }}
        />
        <DropDownField
          label="Valuta"
          value={saleOrderState.order_object.currency_number}
          required={true}
          options={commonAPIstate.currencies.map((item) => (
            <option key={item.currency_id} value={item.currency_id}>
              {item.currency_iso}
            </option>
          ))}
          onChange={(e) => {
            setCardValues({ ...cardValues, currencyId: e.target.value });
            dispatchSaleOrder({
              type: SALE_ACTIONS.CURRENCY_NUMBER,
              data: e.target.value,
            });
          }}
        />
        <TextAreaField
          label="Notater"
          placeholder="Sett inn Notater "
          onChange={(e) => {
            dispatchSaleOrder({
              type: SALE_ACTIONS.NOTE,
              data: e.target.value,
            });
          }}
        />
      </div>
    </>
  );
};

export default SaleOrderHeadingSection;
