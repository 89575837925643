import React from "react"
import ActiveOrdersSection from "./components/ActiveOrdersSection"
import { Riple } from "react-loading-indicators";
import { useMsal } from "@azure/msal-react";

import {
    useMicrosoftCustomToken,
  } from "../../utils/useMicrosoftGraphToken";

function OrdersPage() {
    const microsoftCustomToken = useMicrosoftCustomToken();
    return(
    <>
        <main>
            <div className="mx-4 sm:mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
                <ActiveOrdersSection />
            </div>
        </main>
    </>)
    

}

export default OrdersPage