import React from "react";
import { useMsal } from "@azure/msal-react";

import { loginRequest } from "../../authConfig";

import { ReactComponent as MicrosoftLogo } from "../../assets/logo/microsoft.svg";
import { ReactComponent as TressureChestIcon } from "../../assets/icons/TressureChest.svg";
import ShipIllustration from "../../assets/images/ShipIllustration.png";

export default function LoginPage() {
  const { instance } = useMsal();

  const handleLogin = (loginType) => {
    if (loginType === "popup") {
      instance.loginPopup(loginRequest).catch((e) => {});
    } else if (loginType === "redirect") {
      instance.loginRedirect(loginRequest).catch((e) => {});
    }
  };

  return (
    <div className="flex flex-row h-screen p-4 sm:p-10 gap-2">
      <div className={`basis-full lg:basis-1/2`}>
        <div className="absolute top-2 left-10 flex items-center gap-2">
          <TressureChestIcon className="w-10" />
          <p className="font-bold text-2xl">Salgsportal</p>
        </div>
        <div className="h-full flex flex-col justify-center items-center">
          <p className="font-bold text-3xl text-center">Velkommen tilbake!</p>
          <p className="text-gray-400 text-center">Logg inn på salgsportal</p>
          <button
            onClick={() => handleLogin("redirect")}
            type="button"
            className="text-white bg-deepSkyBlue hover:bg-deepSkyBlue/90 focus:ring-4 focus:outline-none focus:ring-deepSkyBlue/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center gap-2 mr-2 mb-2 mt-8"
          >
            <MicrosoftLogo />
            Sign in with Microsoft
          </button>
        </div>
      </div>
      <div className="basis-1/2 hidden lg:block">
        <img src="" alt="" srcSet="" />
        <img
          className="object-cover h-full w-full rounded-md"
          src={ShipIllustration}
          alt=""
          srcSet=""
        />
      </div>
    </div>
  );
}
