import React, { useState } from "react";

const FreeTextLineButton = ({ onClick }) => {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };
  

  return (
    <div className="relative">
      <button type="button" className="text-deepSkyBlue" onClick={toggleMenu}>
        ⚈ ⚈ ⚈
      </button>
      {showMenu && (
        <button className="w-48 px-4 py-1 absolute right-0 top-6 bg-white rounded-md shadow " type="button" onClick={()=>{
          onClick()
          toggleMenu()
        }}>
          Legg til fritekstlinje
        </button>
      )}
    </div>
  );
};

export default FreeTextLineButton;
