import React, { useState, useContext } from "react";
import { Riple } from "react-loading-indicators";

import { getCustomerProductPriceAPI } from "../../../api/order_api";
import { userDataContext } from "../../../context/userDataContext";
import {
  PURCHASE_ACTIONS,
  purchaseOrderContext,
} from "../../../context/purchaseOrderContext";
import AsyncSearchDropDownField from "../../../components/form/AsyncSearchDropDownField";
import TextField from "../../../components/form/TextField";

import { ReactComponent as DeleteIcon } from "../../../assets/icons/Delete.svg";

const PurchaseProductLineCard = ({
  getProducts,
  productOptions,
  index,
  productLine,
}) => {
  const { purchaseOrderState, dispatchPurchaseOrder } =
    useContext(purchaseOrderContext);
  const { userDataState } = useContext(userDataContext);

  const [customerProductPriceLoading, setCustomerProductPriceLoading] =
    useState(false);

  // update product line in productLines
  const updateProductLine = (index, productLine) => {
    dispatchPurchaseOrder({
      type: PURCHASE_ACTIONS.UPDATE_PRODUCT_LINE,
      data: { index, productLine },
    });
  };

  // delete product line in productLines
  const deleteProductLine = (index) => {
    dispatchPurchaseOrder({
      type: PURCHASE_ACTIONS.DELETE_PRODUCT_LINE,
      data: index,
    });
  };

  // search product in select
  const getNewProductsOptions = async (inputValue, callback) => {
    const data = await getProducts(inputValue);
    callback(data);
  };

  const setProductSelect = async (data) => {
    productLine.productNo = data?.value.productId;
    productLine.name = data?.value.name;
    productLine.unit = data.value.unit;
    productLine.unitType = data.value.unitType;
    updateProductLine(index, productLine);

    // get customer product price and set
    setCustomerProductPriceLoading(true);
    if (purchaseOrderState.customer_details) {
      const customerProductPrice = await getCustomerProductPriceAPI(
        data?.value.productId,
        purchaseOrderState.customer_details.customerId,
        userDataState.accessToken
      );
      productLine.price = customerProductPrice;
      updateProductLine(index, productLine);
    }
    setCustomerProductPriceLoading(false);
  };

  // set default value for select
  let selectValue;
  if (productLine.productNo) {
    selectValue = {
      value: {
        productId: productLine.productNo,
        name: productLine.name,
        unit: productLine.unit,
        unitType: productLine.unitType,
      },
      title: productLine.name,
      subTitle: productLine.unitType,
    };
  } else {
    selectValue = null;
  }

  return (
    <>
      {customerProductPriceLoading && (
        <div className="loading flex items-center justify-center">
          <Riple color="#04c3ff" size="medium" text="" textColor="" />
        </div>
      )}
      <hr className="col-span-2" />
      {productLine.productNo == 7 || (
        <>
          <div className="col-span-2">
            <div className="flex flex-row gap-2">
              <div className="basis-3/4">
                <AsyncSearchDropDownField
                  label="Produkt"
                  loadOptions={getNewProductsOptions}
                  placeholder="Søk etter produkt eller produktnummer..."
                  options={productOptions}
                  onChange={setProductSelect}
                  value={selectValue}
                />
              </div>
              <div className="basis-1/4">
                <TextField
                  label="Enhet"
                  value={productLine.unit}
                  readOnly={true}
                />
              </div>
            </div>
          </div>
        </>
      )}
      {productLine.productNo != 7 || (
        <TextField
          label="Produkt"
          placeholder="Produktnavn"
          value={productLine.description}
          required={true}
          onChange={(e) => {
            productLine.description = e.target.value;
            updateProductLine(index, productLine);
          }}
        />
      )}
      <TextField
        label="Pris"
        placeholder="Sett inn Pris"
        value={productLine.price}
        required={true}
        type="Number"
        min={0.01}
        step="any"
        onChange={(e) => {
          productLine.price = e.target.value;
          updateProductLine(index, productLine);
        }}
        colSpan={1}
      />
      <TextField
        label="Antall"
        placeholder="Sett inn Antall"
        value={productLine.quantity}
        required={true}
        type="Number"
        min={0.01}
        step="any"
        onChange={(e) => {
          productLine.quantity = e.target.value;
          updateProductLine(index, productLine);
        }}
        colSpan={1}
      />
      <p className="col-span-1">
        Beløp:{" "}
        {(productLine.quantity * productLine.price)?.toLocaleString("nb-NO", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
      </p>

      {purchaseOrderState.product_lines.length > 1 && (
        <button
          className="col-span-1 justify-self-end"
          variant="danger"
          onClick={(e) => {
            deleteProductLine(index);
          }}
        >
          <DeleteIcon className="w-5" />
        </button>
      )}
    </>
  );
};

export default PurchaseProductLineCard;
