import { components } from "react-select";

export function SingleValue(props) {
  const { title, subTitle } = props.getValue()[0];

  return (
    <components.SingleValue {...props}>
      <span className="text-lg">{title}</span>{" "}
      <span className="text-gray-400">{subTitle}</span>
    </components.SingleValue>
  );
}

export function Option(props) {
  const { title, subTitle } = props.data;
  return (
    <components.Option {...props}>
      <span className="text-lg">{title}</span>{" "}
      <span className="text-gray-400">{subTitle}</span>
    </components.Option>
  );
}

export function SingleValueWithoutColor(props) {
  const { title, subTitle } = props.getValue()[0];

  return (
    <components.SingleValueWithoutColor {...props}>
      <span className="text-lg">{title}</span>{" "}
      <span className="">{subTitle}</span>
    </components.SingleValueWithoutColor>
  );
}

export function OptionWithoutColor(props) {
  const { title, subTitle } = props.data;
  return (
    <components.OptionWithoutColor {...props}>
      <span className="text-lg">{title}</span>{" "}
      <span className="">{subTitle}</span>
    </components.OptionWithoutColor>
  );
}
