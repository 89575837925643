import React, { useState, useContext } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Riple } from "react-loading-indicators";
import { useMsal } from "@azure/msal-react";


import { userDataContext } from "../../context/userDataContext";
import { TYPE_ORDER, orderAPI } from "../../api/order_api";
import PurchaseHeadingSection from "./components/PurchaseHeadingSection";
import PurchaseProductLineSection from "./components/PurchaseProductLineSection";
import {
  PURCHASE_ACTIONS,
  purchaseOrderContext,
} from "../../context/purchaseOrderContext";
import {
  useMicrosoftCustomToken,
} from "../../utils/useMicrosoftGraphToken";
import InfoCard from "../../components/ui/InfoCard";
import { ReactComponent as DiamondIcon } from "../../assets/icons/Diamond.svg";
import { CurrencyDollarIcon } from "@heroicons/react/24/solid";

import "../../style/common.css";
import "react-toastify/dist/ReactToastify.css";

function PurchaseOrderPage() {
  const microsoftCustomToken = useMicrosoftCustomToken();
  const { userDataState } = useContext(userDataContext);
  const { purchaseOrderState, dispatchPurchaseOrder } =
    useContext(purchaseOrderContext);


  const [purchasePostLoading, setPurchasePostLoading] = useState(false);

  // get user token after every 1 hour
  const { instance, accounts } = useMsal();
  const createMicrosoftGraphToken = async () => {
    const response = await instance.acquireTokenSilent({
      scopes: [`User.Read ChannelMessage.Send Tasks.ReadWrite Mail.Send GroupMember.Read.All`],
      account: accounts[0]
    });

    return response.accessToken;
  }

  const totalProductLinesAmount = () =>
    purchaseOrderState.product_lines.reduce(
      (sum, { price, quantity }) => sum + price * quantity,
      0
    );

  const handleSubmit = async (e) => {
    e.preventDefault();

    setPurchasePostLoading(true);
    const microsoftGraphToken = await createMicrosoftGraphToken();

    const res = await orderAPI(
      purchaseOrderState,
      microsoftGraphToken,
      microsoftCustomToken,
      userDataState.account.username,
      userDataState.account.localAccountId,
      userDataState.account.name,
      TYPE_ORDER.PURCHASE
    );
    setPurchasePostLoading(false);

    if (res.status == 200) {
      toast.success("Ordre opprettet");
      // reset form after submit and if response status is 200
      if (process.env.NODE_ENV === "production") {
        document.getElementById("purchaseOrderForm").reset();
        dispatchPurchaseOrder({
          type: PURCHASE_ACTIONS.RESET_FORM,
        });
      }
    } else if (res.status == 500) {
      toast.error("Ordre mislykkes (500 - Internal server error)");
    } else {
      toast.error("Ordre mislykkes");
    }
  };

  return (
    <>
      <ToastContainer autoClose={2000} />
      <main>
        <div className="mx-4 sm:mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
          {purchasePostLoading && (
            <div className="loading flex items-center justify-center">
              <Riple color="#04c3ff" size="medium" text="" textColor="" />
            </div>
          )}
          <form id="purchaseOrderForm" onSubmit={handleSubmit}>
            <PurchaseHeadingSection />
            <PurchaseProductLineSection />
            <div className="pt-14 pb-8">
              <InfoCard
                label="Totalt beløp"
                data={
                  totalProductLinesAmount()?.toLocaleString("nb-NO", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }) || "0.0"
                }
                icon={<CurrencyDollarIcon className="w-12 text-deepSkyBlue" />}
              />
            </div>
            <button
              type="submit"
              className="w-full text-white bg-deepSkyBlue hover:bg-deepSkyBlue/90 focus:ring-4 focus:outline-none focus:ring-deepSkyBlue/50 rounded-md text-xl py-2 text-center flex items-center justify-center gap-2"
            >
              <DiamondIcon />
              Opprett kjøpsordre
              <DiamondIcon />
            </button>
          </form>
        </div>
      </main>
    </>
  );
}

export default PurchaseOrderPage;
