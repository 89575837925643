import AsyncSelect from "react-select/async";

import {
  OptionWithoutColor,
  SingleValueWithoutColor,
} from "../../utils/selectStyle";

const AsyncSearchDropDownField = ({
  label,
  loadOptions,
  options,
  onChange,
  value,
  placeholder = "Søk etter fartøy eller kunde...",
  colSpan = 2,
  required = true
}) => {
  const style = {
    control: (base, state) => ({
      ...base,
      backgroundColor: "#f2f3f9",
      padding: "0.300rem",
      border: state.isFocused ? 0 : 0,
    }),
  };

  return (
    <div
      className={`mb-2 col-span-2 ${
        colSpan != 2 ? "sm:col-span-1" : "sm:col-span-2"
      }`}
    >
      <label className="block text-md font-light leading-6 text-gray-900">
        {label}
      </label>
      <div className="mt-2 ">
        <AsyncSelect
          cacheOptions
          className="block w-full rounded-md border-0 p-1 bg-whiteSmoke text-gray-900 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6"
          styles={style}
          classNamePrefix=""
          options={loadOptions}
          value={value}
          getOptionLabel={(loadOptions) => {
            return `${loadOptions.title} ${loadOptions.subTitle}`;
          }}
          components={{
            SingleValueWithoutColor,
            OptionWithoutColor,
            NoOptionsMessage: () => "Ingen treff...",
          }}
          // inputId={options.actorId}
          isSearchable={true}
          isClearable={false}
          name="test"
          defaultOptions={options}
          onChange={onChange}
          loadOptions={loadOptions}
          placeholder={placeholder}
          required={required}
        ></AsyncSelect>
      </div>
    </div>
  );
};

export default AsyncSearchDropDownField;
