import { Logtail } from "@logtail/browser";
const logtail = new Logtail(process.env.REACT_APP_LOGTAIL_TOKEN);

export async function microsoftSendEmail(
  title,
  content,
  recipient,
  accessToken,
  ccRecipient = "",
  attachment = "",
  attachmentName = ""
) {
  logtail.info("Input - Send mail:");
  logtail.info(`title: ${title}`);
  logtail.info(`content: ${content}`);
  logtail.info(`recipient: ${recipient}`);
  logtail.info(`accessToken: ${accessToken}`);
  logtail.info(`ccRecipient: ${ccRecipient}`);
  logtail.info(`attachmentName: ${attachmentName}`);
  let myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${accessToken}`);
  let attachments = [];
  if (attachment) {
    attachments = [
      {
        "@odata.type": "#microsoft.graph.fileAttachment",
        name: attachmentName,
        contentBytes: attachment,
        isInLine: "true",
      },
    ];
  }

  let raw = JSON.stringify({
    message: {
      subject: title,
      body: {
        contentType: "Text",
        content: content,
      },
      attachments: attachments,
      toRecipients: [
        {
          emailAddress: {
            address: recipient,
          },
        },
      ],
      ccRecipients: [
        {
          emailAddress: {
            address: ccRecipient,
          },
        },
      ],
    },
    saveToSentItems: "true",
  });
  logtail.info(`Email body${raw}`);

  const mail_response = await fetch(
    "https://graph.microsoft.com/v1.0/me/sendMail",
    {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    }
  )
    .then((response) => response.json())
    .then((result) => logtail.info("Sendmail result:", result))
    .catch((error) => logtail.error(error))
    .finally(() => logtail.flush());
  logtail.flush();
}

export async function microsoftCreatePlannerTask(
  title,
  planId,
  bucketId,
  assignee,
  dueDateTime,
  notes,
  accessToken,
  assigneeGroupId = ""
) {
  logtail.info("Input - Create planner task:");
  logtail.info(`title: ${title}`);
  logtail.info(`planId: ${planId}`);
  logtail.info(`bucketId: ${bucketId}`);
  logtail.info(`assignee: ${assignee}`);
  logtail.info(`dueDateTime: ${dueDateTime}`);
  logtail.info(`notes: ${notes}`);
  logtail.info(`accessToken: ${accessToken}`);
  logtail.info(`assigneeGroupId: ${assigneeGroupId}`);
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${accessToken}`);
  let assignments = {
    [assignee]: {
      "@odata.type": "microsoft.graph.plannerAssignment",
      orderHint: " !",
    },
  };
  if (assigneeGroupId) {
    assignments = await fetch(
      `https://graph.microsoft.com/v1.0/groups/${assigneeGroupId}/members`,
      {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      }
    )
      .then((response) => response.json())
      .then((result) => {
        let resultValues = result.value;
        let resultObject = Object.assign(
          {},
          ...resultValues.map((assignee) => ({
            [assignee.id]: {
              "@odata.type": "microsoft.graph.plannerAssignment",
              orderHint: " !",
            },
          }))
        );
        delete resultObject[""];
        console.log(resultObject);
        logtail.info("Assignee group: ", resultObject);
        resultObject[[assignee]] = {
          "@odata.type": "microsoft.graph.plannerAssignment",
          orderHint: " !",
        };
        return resultObject;
      });
  }
  // console.log(assigneeGroupMembers)
  let body = {
    planId: planId,
    bucketId: bucketId,
    title: title,
    dueDateTime: `${dueDateTime}Z`,
    assignments,
  };
  console.log(body);
  logtail.info("Planner body: ", body);

  await fetch("https://graph.microsoft.com/v1.0/planner/tasks", {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(body),
    redirect: "follow",
  })
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      logtail.info("Post planner result: ", result);
      console.log(result);
      let taskId = result.id;
      return taskId;
    })
    .then((taskId) => {
      fetch(
        `https://graph.microsoft.com/v1.0/planner/tasks/${taskId}/details`,
        {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        }
      )
        .then((response) => {
          return response.headers.get("etag");
        })
        .then((etag) => {
          myHeaders.append("If-Match", etag);
          fetch(
            `https://graph.microsoft.com/v1.0/planner/tasks/${taskId}/details`,
            {
              method: "PATCH",
              headers: myHeaders,
              body: JSON.stringify({
                description: notes ? notes : "",
                previewType: "automatic",
              }),
              redirect: "follow",
            }
          )
            .then((response) => response.json())
            .then((result) => logtail.info("Planner result:", result))
            .catch((error) => logtail.error(error));
        })
        .catch((error) => logtail.error(error));
    })
    .catch((error) => logtail.error(error))
    .finally(() => logtail.flush());
}

export async function microsoftSendTeamsMessage(
  subject,
  content,
  contentType,
  teamId,
  channelId,
  accessToken
) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${accessToken}`);

  var raw = JSON.stringify({
    subject: subject,
    body: {
      contentType: contentType,
      content: content,
    },
  });

  await fetch(
    `https://graph.microsoft.com/beta/teams/${teamId}/channels/${channelId}/messages`,
    {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    }
  )
    .then((response) => response.text())
    .then((result) => logtail.info(`Teams result: ${result}`))
    .catch((error) => logtail.error(error))
    .finally(() => logtail.flush());
}
