const DropDownField = ({
  label,
  value,
  options,
  onChange,
  colSpan = 2,
  required = false,
  ref,
}) => {
  return (
    <div
      className={`mb-2 col-span-2  ${
        colSpan != 2 ? "sm:col-span-1" : "sm:col-span-2"
      }`}
    >
      <label
        htmlFor="country"
        className="block text-md font-light leading-6 text-gray-900"
      >
        {label}
      </label>
      <div className="mt-2">
        <select
          ref={ref}
          onChange={onChange}
          value={value}
          className="appearance-none block w-full rounded-md border-0 p-3.5 bg-whiteSmoke text-gray-900 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6"
          required={required}
        >
          {options}
        </select>
      </div>
    </div>
  );
};

export default DropDownField;
