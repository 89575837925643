import React, { useState, useContext, useEffect, useRef } from "react";
import { parseISO } from 'date-fns'
import { Riple } from "react-loading-indicators";

import { userDataContext } from "../../../context/userDataContext";
import { commonAPIContext } from "../../../context/commonAPIContext";
import {
  PURCHASE_ACTIONS,
  purchaseOrderContext,
} from "../../../context/purchaseOrderContext";
import { PAGE_TYPE } from "../../../utils/enums/pageType";
import {
  getSuppliersAPI,
  getSupplierAmountDomesticAPI,
  getSupplierReceivingAddressesAPI,
} from "../../../api/order_api";
import InfoCard from "../../../components/ui/InfoCard";
import DropDownField from "../../../components/form/DropDownField";
import TextField from "../../../components/form/TextField";
import TextAreaField from "../../../components/form/TextAreaField";
import DatePickerField from "../../../components/form/DatePickerField";
import AsyncSearchDropDownField from "../../../components/form/AsyncSearchDropDownField";
import SearchDropDownField from "../../../components/form/SearchDropDownField";

import { ReactComponent as ProfileIcon } from "../../../assets/icons/Profile.svg";
import { ReactComponent as CardIcon } from "../../../assets/icons/Card.svg";
import { ReactComponent as MoneyIcon } from "../../../assets/icons/Money.svg";

const PurchaseHeadingSection = ({ pageType = PAGE_TYPE.PURCHASE }) => {
  // check which page
  const isTradingPageType = pageType == PAGE_TYPE.TRADING;

  // hard code
  const taxCodesFilter = isTradingPageType ? [1, 5, 8] : [1, 8];

  // contexts
  const { userDataState } = useContext(userDataContext);
  const { commonAPIstate } = useContext(commonAPIContext);
  const { purchaseOrderState, dispatchPurchaseOrder } =
    useContext(purchaseOrderContext);

  const prevStateRef = useRef(purchaseOrderState);

  const initialPurchaseState = {
    actorId: "",
    supplierName: "",
    supplierId: "",
    creditLimit: "",
    currencyId: "",
    paymentTermId: "",
  };

  // states
  const [supplier, setSupplier] = useState(initialPurchaseState);
  const [suppliersOptions, setSuppliersOptions] = useState([]);
  const [amountDomestic, setAmountDomestic] = useState("0");
  const [receivingAddresses, setReceivingAddresses] = useState([]);
  const [supplierExtraInfoLoading, setSupplierExtraInfoLoading] =
    useState(false);
  const [supplierLoading, setSupplierLoading] = useState(false);
  // just for reset form
  const [selectResetForm, setSelectResetForm] = useState({
    supplier: null,
    receivingAddress: null,
    deliveryMethod: null,
  });

  const getSuppliersOptions = async (supplierName) => {
    let res = await getSuppliersAPI(supplierName, userDataState.accessToken);
    let arr = res.map((supplier) => ({
      value: {
        actorId: supplier.actor_id,
        supplierName: supplier.name,
        supplierId: supplier.supplier_id,
        creditLimit: supplier.credit_limit,
        currencyId: supplier.currency_id,
        paymentTermId: supplier.payment_term_id,
      },
      title: supplier.name,
      subTitle: "",
    }));
    setSuppliersOptions(arr);

    return arr;
  };

  const getSuppliersOptionsWhenPageBuild = async () => {
    if (suppliersOptions) {
      setSupplierLoading(true);
      await getSuppliersOptions();
      setSupplierLoading(false);
    }
  };

  const getSupplierExtraInfo = async (supplierId) => {
    setSupplierExtraInfoLoading(true);
    const resAmountDomestic = await getSupplierAmountDomesticAPI(
      supplierId,
      userDataState.accessToken
    );
    const resReceivingAddresses = await getSupplierReceivingAddressesAPI(
      supplierId,
      userDataState.accessToken
    );
    setAmountDomestic(resAmountDomestic);
    setReceivingAddresses(resReceivingAddresses);
    setSupplierExtraInfoLoading(false);

    dispatchPurchaseOrder({
      type: PURCHASE_ACTIONS.DOMESTIC_AMOUNT,
      data: resAmountDomestic,
    });
  };

  // re again request suppliers
  const loadOptions = async (inputValue, callback) => {
    const data = await getSuppliersOptions(inputValue);
    callback(data);
  };

  const setDefaultValueWhenPageBuild = () => {
    dispatchPurchaseOrder({
      type: PURCHASE_ACTIONS.WAREHOUSE,
      data: commonAPIstate.warehouses[0],
    });
  };

  useEffect(() => {
    if (
      prevStateRef.current.reset_form_state !==
      purchaseOrderState.reset_form_state
    ) {
      setSupplier(initialPurchaseState);
      setSelectResetForm({
        supplier: null,
        receivingAddress: null,
        deliveryMethod: null,
      });
      setAmountDomestic("0");
      prevStateRef.current = purchaseOrderState;
    }
  }, [purchaseOrderState.reset_form_state]);

  useEffect(() => {
    setDefaultValueWhenPageBuild();
    getSuppliersOptionsWhenPageBuild();
  }, []);

  if (supplierLoading) {
    return (
      <div className="loading-indicator ">
        <Riple color="#04c3ff" size="medium" text="" textColor="" />
      </div>
    );
  }

  return (
    <>
      {supplierExtraInfoLoading && (
        <div className="loading flex items-center justify-center">
          <Riple color="#04c3ff" size="medium" text="" textColor="" />
        </div>
      )}
      <div className="grid grid-cols-2 gap-4 border-[1px] border-deepSkyBlue rounded-2xl p-10">
        <p className="col-span-full text-deepSkyBlue text-2xl mb-4">
          Innkjøpsordre
        </p>
        <AsyncSearchDropDownField
          label="Leverandør"
          loadOptions={loadOptions}
          placeholder="Søk etter leverandør..."
          options={suppliersOptions}
          value={selectResetForm.supplier}
          onChange={(data) => {
            setSupplier(data.value);
            selectResetForm.supplier = data;
            setSelectResetForm(data);
            getSupplierExtraInfo(data.value.supplierId);
            dispatchPurchaseOrder({
              type: PURCHASE_ACTIONS.SUPPLIER_NUMBER,
              data: data.value,
              currency_number: data.value.currencyId
                ? data.value.currencyId
                : 47,
            });
          }}
        />
        <InfoCard
          label="Leverandør"
          data={supplier.supplierName}
          icon={<ProfileIcon />}
          colSpan={2}
        />
        <InfoCard
          label="Kredittgrense"
          label2="Utestående"
          data={supplier?.creditLimit?.toLocaleString("nb-NO") || ""}
          data2={
            supplierExtraInfoLoading
              ? "Loading.."
              : amountDomestic.toLocaleString("nb-NO", {
                maximumFractionDigits: 0,
              }) || "0"
          }
          icon={<CardIcon />}
        />
        <InfoCard
          label="Tilgjengelig ramme"
          data={
            (supplier?.creditLimit - amountDomestic).toLocaleString("nb-NO", {
              maximumFractionDigits: 0,
            }) || ""
          }
          icon={<MoneyIcon />}
        />
        <DropDownField
          label="Betalingsbetingelse"
          value={supplier.paymentTermId || purchaseOrderState.purchase_object.payment_terms}
          required={true}
          options={commonAPIstate.paymentTerms.map((term) => (
            <option key={term.payment_term_id} value={term.payment_term_id}>
              {term.name}
            </option>
          ))}
          onChange={(e) => {
            dispatchPurchaseOrder({
              type: PURCHASE_ACTIONS.PAYMENT_TERMS,
              data: e.target.value,
            });
            setSupplier({ ...supplier, paymentTermId: e.target.value });
          }}
        />
        {!isTradingPageType && (
          <DropDownField
            label="Leveres til lager"
            value={purchaseOrderState.warehouse_number}
            options={commonAPIstate.warehouses
              .filter((warehouse) => warehouse.warehouse_id != 8)
              .map((warehouse) => (
                <option
                  key={warehouse.id}
                  value={JSON.stringify(warehouse)}
                >
                  {warehouse.name}
                </option>
              ))}
            onChange={(e) => {
              dispatchPurchaseOrder({
                type: PURCHASE_ACTIONS.WAREHOUSE,
                data: JSON.parse(e.target.value),
              });
            }}
            colSpan={1}
          />
        )}
        <DatePickerField
          label="Ønsket leveringsdato"
          required={true}
          value={purchaseOrderState.purchase_object.delivery_date ? parseISO(purchaseOrderState.purchase_object.delivery_date) : null}
          onChange={(date) => {
            dispatchPurchaseOrder({
              type: PURCHASE_ACTIONS.DELIVERY_DATE,
              data: date,
            });
          }}
          colSpan={isTradingPageType ? 2 : 1}
        />
         <TextField
          label="Leveringssted"
          placeholder="Sett inn leveringssted"
          extraClassName="uppercase"
          onChange={(e) => {
            dispatchPurchaseOrder({
              type: PURCHASE_ACTIONS.INFORMATION_5,
              data: e.target.value,
            });
          }}
        />
        <SearchDropDownField
          label="Leveringsadresse"
          placeholder="Velg leveringsadresse"
          value={selectResetForm.receivingAddress}
          options={receivingAddresses.map((address) => ({
            value: address,
            title: address.name,
            subTitle: `${address.address_1}, ${address.address_2}, ${address.postal_code} ${address.postal_city}`,
          }))}
          onChange={(data) => {
            selectResetForm.receivingAddress = data;
            setSelectResetForm(data);
            dispatchPurchaseOrder({
              type: PURCHASE_ACTIONS.DELIVERY_ADDRESS,
              data: data.value.actor_id,
            });
          }}
        />

        <SearchDropDownField
          label="Leveringsmåte"
          placeholder="Velg leveringsmåte"
          value={selectResetForm.deliveryMethod}
          options={commonAPIstate.deliveryMethods}
          onChange={(data) => {
            selectResetForm.deliveryMethod = data;
            setSelectResetForm(data);
            dispatchPurchaseOrder({
              type: PURCHASE_ACTIONS.DELIVERY_METHOD,
              data: data.value.text_number,
            });
          }}
        />
        <DropDownField
          label="Mva-kode"
          value={purchaseOrderState.purchase_object.tax_code}
          options={commonAPIstate.taxCodes
            .filter((item) => taxCodesFilter.includes(item.tax_code_id))
            .map((item) => (
              <option key={item.tax_code_id} value={item.tax_code_id}>
                {item.name}
              </option>
            ))}
          onChange={(e) => {
            dispatchPurchaseOrder({
              type: PURCHASE_ACTIONS.TAX_CODE,
              data: e.target.value,
            });
          }}
        />
        <DropDownField
          label="Spesialavgiftsfritak"
          options={
            <>
              {commonAPIstate.exemptFromSpecialTax
                .map((item)=> (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
            </>
          }
          onChange={(e) => {
            dispatchPurchaseOrder({
              type: PURCHASE_ACTIONS.EXEMPT_FROM_SPECIAL_TAX,
              data: parseInt(e.target.value),
            });
          }}
        />
        <TextField
          label="Leverandørens referansenummer/SO-nummer"
          placeholder="Sett inn leverandørens referansenummer/SO-nummer"
          extraClassName="uppercase"
          onChange={(e) => {
            dispatchPurchaseOrder({
              type: PURCHASE_ACTIONS.CUSTOMER_OR_SUPPLIER_ORDER_NUMBER,
              data: e.target.value,
            });
          }}
        />
        <TextField
          label="Leverandørens referanseperson"
          extraClassName="uppercase"
          placeholder="Sett inn leverandørens referanseperson"
          onChange={(e) => {
            dispatchPurchaseOrder({
              type: PURCHASE_ACTIONS.YOUR_REFERENCE,
              data: e.target.value,
            });
          }}
        />
        <DropDownField
          label="Valuta"
          value={purchaseOrderState.purchase_object.currency_number}
          required={true}
          options={commonAPIstate.currencies.map((item) => (
            <option key={item.currency_id} value={item.currency_id}>
              {item.currency_iso}
            </option>
          ))}
          onChange={(e) => {
            setSupplier({ ...supplier, currencyId: e.target.value });
            dispatchPurchaseOrder({
              type: PURCHASE_ACTIONS.CURRENCY_NUMBER,
              data: e.target.value,
            });
          }}
        />
        <TextAreaField
          label="Notater"
          placeholder="Sett inn Notater "
          onChange={(e) => {
            dispatchPurchaseOrder({
              type: PURCHASE_ACTIONS.NOTE,
              data: e.target.value,
            });
          }}
        />
      </div>
    </>
  );
};

export default PurchaseHeadingSection;
