import { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";

export function useMicrosoftGraphToken() {
    const [accessToken, setAccessToken] = useState('')
    const { instance, accounts } = useMsal();
    useEffect(() => {
        async function fetchToken(scopes) {
            const response = await instance.acquireTokenSilent({
                scopes: [scopes],
                account: accounts[0]
            });
            setAccessToken(response.accessToken)
        }
        fetchToken(`User.Read ChannelMessage.Send Tasks.ReadWrite Mail.Send GroupMember.Read.All`)
    }, [])
    return accessToken;
}

export function useMicrosoftCustomToken() {
    const [accessToken, setAccessToken] = useState('')
    const { instance, accounts } = useMsal();
    useEffect(() => {
        async function fetchToken(scopes) {
            const response = await instance.acquireTokenSilent({
                scopes: [scopes],
                account: accounts[0]
            });
            setAccessToken(response.accessToken)
        }
        fetchToken(`api://${process.env.REACT_APP_MICROSOFT_CLIENT_ID}/${process.env.REACT_APP_MICROSOFT_REQUIRED_SCOPE_BITVERSE}`)
    }, [])
    return accessToken
}