import { NavLink } from "react-router-dom";
import { Disclosure } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { useMsal } from "@azure/msal-react";

import { ReactComponent as TressureChestIcon } from "../../assets/icons/TressureChest.svg";
import { SALE_ACTIONS, saleOrderContext } from "../../context/saleOrderContext";
import {
  PURCHASE_ACTIONS,
  purchaseOrderContext,
} from "../../context/purchaseOrderContext";
import { useContext } from "react";

const navigation = [
  { name: "Marine salg", path: "/" },
  { name: "Trading", path: "/trading" },
  { name: "Innkjøp", path: "/purchase" },
  { name: "Ordre (Beta*)", path: "/orders"}
];

export default function PageHeader() {
  const { instance } = useMsal();
  const { dispatchSaleOrder } = useContext(saleOrderContext);
  const { dispatchPurchaseOrder } = useContext(purchaseOrderContext);

  const resetForm = () => {
    dispatchSaleOrder({
      type: SALE_ACTIONS.RESET_FORM,
    });
    dispatchPurchaseOrder({
      type: PURCHASE_ACTIONS.RESET_FORM,
    });
  };

  const handleLogout = (logoutType) => {
    if (logoutType === "popup") {
      instance.logoutPopup({
        postLogoutRedirectUri: "/",
        mainWindowRedirectUri: "/",
      });
    } else if (logoutType === "redirect") {
      instance.logoutRedirect({
        postLogoutRedirectUri: "/",
      });
    }
  };
  return (
    <>
      <div className="min-h-full sticky top-0 z-50 mb-4">
        <Disclosure as="nav" className="bg-gray-100">
          {({ open }) => (
            <>
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="flex h-16 items-center justify-between">
                  <div className="flex items-center">
                    <div className="flex items-center gap-2">
                      <TressureChestIcon className="w-10" />
                      <p className="font-bold text-2xl">Salgsportal</p>
                    </div>
                    <div className="hidden md:block">
                      <div className="ml-10 flex items-baseline space-x-4">
                        {navigation.map((item) => (
                          <NavLink
                            onClick={resetForm}
                            to={{
                              pathname: item.path,
                            }}
                            key={item.name}
                            className={(navData) =>
                              `${
                                navData.isActive
                                  ? "decoration-deepSkyBlue underline decoration-2"
                                  : "hover:underline hover:decoration-deepSkyBlue hover:decoration-2 no-underline"
                              } text-black px-3 py-2 text-lg underline-offset-8`
                            }
                          >
                            {item.name}
                          </NavLink>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="hidden md:block">
                    <div className="ml-4 flex items-center md:ml-6">
                      <button
                        onClick={() => handleLogout("redirect")}
                        type="button"
                        className="rounded-sm bg-deepSkyBlue hover:bg-deepSkyBlue/90 text-white  px-4 py-2 focus:outline-none focus:ring-2 focus:ring-deepSkyBlue/50"
                      >
                        Logout
                      </button>
                    </div>
                  </div>
                  <div className="-mr-2 flex md:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-deepSkyBlue p-2 text-white hover:ring-offset-sky-300 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-sky-300">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XMarkIcon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      ) : (
                        <Bars3Icon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>

              <Disclosure.Panel className="md:hidden">
                <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
                  {navigation.map((item) => (
                    <NavLink
                      onClick={resetForm}
                      to={{
                        pathname: item.path,
                      }}
                      key={item.name}
                      className={(navData) =>
                        `${
                          navData.isActive
                            ? "decoration-deepSkyBlue underline decoration-2"
                            : "hover:underline hover:decoration-deepSkyBlue hover:decoration-2 no-underline"
                        } block text-black px-3 py-2 text-lg underline-offset-8`
                      }
                    >
                      {item.name}
                    </NavLink>
                  ))}
                </div>
                <div className="border-t border-deepSkyBlue p-4">
                  <button
                    onClick={() => handleLogout("redirect")}
                    type="button"
                    className="ml-auto rounded-sm bg-deepSkyBlue text-white px-4 py-2 focus:outline-none focus:ring-2 focus:ring-deepSkyBlue/50"
                  >
                    Logout
                  </button>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    </>
  );
}
