import React, { createContext, useState } from "react";

// create common api context
export const commonAPIContext = createContext();

// pass data around tree
const CommonAPIContextProvider = (props) => {
  const [commonAPIstate, dispatchCommonAPI] = useState({
    currencies: [],
    paymentTerms: [],
    taxCodes: [],
    exemptions: [],
    warehouses: [],
    deliveryMethods: [],
    exemptFromSpecialTax: [],
  });

  return (
    <commonAPIContext.Provider value={{ commonAPIstate, dispatchCommonAPI }}>
      {props.children}
    </commonAPIContext.Provider>
  );
};

export default CommonAPIContextProvider;
