import { sendMessageToSlack } from "../utils/slackAPIs";
import { Logtail } from "@logtail/browser";
import {
  microsoftSendEmail,
  microsoftCreatePlannerTask,
  microsoftSendTeamsMessage,
} from "../utils/microsoftGraphAPIs";
import { downloadBlob } from "../utils/fileUtils";

const logtail = new Logtail(process.env.REACT_APP_LOGTAIL_TOKEN);
const API_BASE_URL = process.env.REACT_APP_BACKEND_API_URI;

export async function getProductsAPI(productName = "", accessToken) {
  return fetch(`${API_BASE_URL}/products?product_name=${productName}`, {
    method: "GET",
    headers: {
      accept: `application/json`,
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then((response) => response.json())
    .catch((err) => console.log(err));
}

export async function getVesselsAPI(vesselName = "", accessToken) {
  return fetch(`${API_BASE_URL}/vessels?vessel_name=${vesselName}`, {
    method: "GET",
    headers: {
      accept: `application/json`,
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then((response) => response.json())
    .catch((err) => console.log(err));
}

export async function getOpenOrdersAPI(accessToken, ...kwargs) {
  console.log(kwargs);
  return fetch(`${API_BASE_URL}/open_orders`, {
    method: "GET",
    headers: {
      accept: `application/json`,
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then((response) => response.json())
    .catch((err) => console.log(err));
}

export async function getCustomersAPI(customerName = "", accessToken) {
  return fetch(`${API_BASE_URL}/customers?customer_name=${customerName}`, {
    method: "GET",
    headers: {
      accept: `application/json`,
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then((response) => response.json())
    .catch((err) => console.log(err));
}

export async function getSuppliersAPI(supplierName = "", accessToken) {
  return fetch(`${API_BASE_URL}/suppliers?supplier_name=${supplierName}`, {
    method: "GET",
    headers: {
      accept: `application/json`,
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then((response) => response.json())
    .catch((err) => console.log(err));
}

export async function getCustomerAmountDomesticAPI(customerId, accessToken) {
  return fetch(
    `${API_BASE_URL}/customers/${customerId}/open_transactions/amount_domestic`,
    {
      method: "GET",
      headers: {
        accept: `application/json`,
        Authorization: `Bearer ${accessToken}`,
      },
    }
  )
    .then((response) => response.json())
    .catch((err) => console.log(err));
}

export async function getSupplierAmountDomesticAPI(supplierId, accessToken) {
  return fetch(
    `${API_BASE_URL}/suppliers/${supplierId}/open_transactions/amount_domestic`,
    {
      method: "GET",
      headers: {
        accept: `application/json`,
        Authorization: `Bearer ${accessToken}`,
      },
    }
  )
    .then((response) => response.json())
    .catch((err) => console.log(err));
}

export async function getSupplierReceivingAddressesAPI(
  supplierId,
  accessToken
) {
  return fetch(`${API_BASE_URL}/suppliers/${supplierId}/receiving_addresses`, {
    method: "GET",
    headers: {
      accept: `application/json`,
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then((response) => response.json())
    .catch((err) => console.log(err));
}

export async function getCustomerProductPriceAPI(
  productId,
  customerId,
  accessToken
) {
  let response = await fetch(
    `${API_BASE_URL}/prices/${productId}/${customerId}`,
    {
      method: "GET",
      headers: {
        accept: `application/json`,
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  if (response.status === 200) {
    return response.json();
  } else {
  }

  return 0.0;
}

export async function orderAPI(
  orderState,
  microsoftGraphToken,
  microsoftCustomToken,
  userAuthEmail,
  userAuthId,
  userAuthName,
  typeOrder,
  purchaseNo,
  supplierNo
) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${microsoftCustomToken}`);
  myHeaders.append("Access-Control-Request-Method", "POST");
  myHeaders.append(
    "Access-Control-Request-Headers",
    "X-PINGOTHER, Content-Type"
  );
  myHeaders.append(
    "Access-Control-Allow-Headers",
    "Content-Type, Authorization"
  );

  // remove unit from order line for send via api
  let orderLines = orderState.product_lines;
  let orderLinesErp = orderLines.map((line) => {
    const newline = {
      price: line.price,
      productNo: line.productNo,
      quantity: line.quantity,
      description: line.description,
      discountPercent: line.discountPercent,
      discountAmountInCurrency: line.discountAmountInCurrency,
    };
    // this field relate trading
    if (purchaseNo) {
      newline.purchaseNo = purchaseNo;
      newline.supplierNo = supplierNo;
    }
    return newline;
  });

  // prepare json
  var raw = JSON.stringify({
    delivery_address: {
      id:
        typeOrder == TYPE_ORDER.SALE
          ? orderState.customer_details.picker_type === "vessel"
            ? orderState.customer_details.actorId
            : null
          : orderState.purchase_object.delivery_address,
    },
    order_lines: orderLinesErp,
  });

  // create params
  let paramsWithValue = "";
  const paramsObject =
    typeOrder === TYPE_ORDER.SALE
      ? orderState.order_object
      : orderState.purchase_object;

  for (const [key, value] of Object.entries(paramsObject)) {
    if (value) {
      paramsWithValue += `${key}=${value}&`;
    }
  }

  paramsWithValue = paramsWithValue.slice(0, -1);

  paramsWithValue += `&our_reference=${userAuthName.toUpperCase()}`;

  // this params relate trading
  if (purchaseNo) {
    paramsWithValue += `&purchase_id=${purchaseNo}&supplier_number=${supplierNo}`;
  }

  let response;
  // this felid relate trading post
  let purchaseNoResponseValue;
  let supplierNoResponseValue;

  try {
    response = await fetch(`${API_BASE_URL}/orders?${paramsWithValue}`, {
      method: "POST",
      body: raw,
      headers: myHeaders,
    });
  } catch (error) {
    console.log(error);
  }

  if (response.status === 200) {
    await response.json().then(async (response) => {
      console.log(response.message);
      console.log(orderLines);

      purchaseNoResponseValue = response.message.orderNo;
      supplierNoResponseValue = response.message.supplierNo;

      let product_list = orderLines
        .map((line) => {
          return [
            `- ${line.quantity} ${line?.unit} | ${line?.unitType}, ${
              line.name
            } ${line?.description ? line.description : ""}(${line.productNo})`,
          ];
        })
        .join("\n");
      let deliveryPlace = response.message.information5;
      let vesselName = response.message.information6;
      let customerName = response.message.name;
      let deliveryDate = response.message.confirmedDeliveryDate;
      let customerReferenceId = response.message.customerOrSupplierOrderNo;
      let customerDestination = response.message.information1;
      let emailMessage = `${vesselName ? `Fartøy: ${vesselName}\n` : ""}${
        typeOrder === TYPE_ORDER.SALE ? "Kunde" : "Leverandør"
      }: ${customerName}\nLeveringsdato: ${deliveryDate}\nLeveringssted: ${deliveryPlace}\nKundens referansenummer: ${customerReferenceId}\nDestinasjon: ${customerDestination}\n\nProdukter:\n${product_list}\n\nNotater:\n${
        orderState.note
      }`;
      let title = `${vesselName ? vesselName : customerName} | ${
        typeOrder === TYPE_ORDER.SALE ? "SO" : "PO"
      }${response.message.orderNo}${vesselName ? "🚢" : ""}`;
      // send success response via email and create planner & team

      // Todo: Lookup attachment on endpoint /orders/{order_id}/documents The base64-endoded attachment is available in response.message
      const responseOrders = await fetch(
        `${API_BASE_URL}/orders/${response.message.orderNo}/documents?document_type=${orderState.document_type}&approval=false`,
        {
          method: "GET",
          headers: {
            accept: `application/json`,
            Authorization: `Bearer ${microsoftCustomToken}`,
          },
        }
      );

      const attachment = await responseOrders.json();
      const attachmentMessage = await attachment.message;
      const attachmentName = `${response.message.orderNo}.pdf`;
      const base64Attachment = await fetch(
        `data:application/pdf;base64,${attachmentMessage}`
      );
      const attachmentBlob = await base64Attachment.blob();
      await downloadBlob(attachmentBlob, attachmentName);
      const ccRecipient =
        typeOrder === TYPE_ORDER.SALE
          ? process.env.REACT_APP_MICROSOFT_ORDER_SUMMARY_EMAIL
          : process.env.REACT_APP_MICROSOFT_PURCHASE_ORDER_SUMMARY_EMAIL;
      await new Promise((resolve) => setTimeout(resolve, 1000));
      logtail.info("Trigger microsoftSendEmail");
      await microsoftSendEmail(
        title,
        emailMessage,
        userAuthEmail,
        microsoftGraphToken,
        ccRecipient,
        attachmentMessage,
        attachmentName
      );
      logtail.info("Trigger 1 Second timer");
      await new Promise((resolve) => setTimeout(resolve, 1000));
      logtail.info("1 second timer finished");
      // if (typeOrder == TYPE_ORDER.SALE) {
      //   await microsoftSendEmail(
      //     title,
      //     emailMessage,
      //     userAuthEmail,
      //     microsoftGraphToken,
      //     process.env.REACT_APP_MICROSOFT_ORDER_SUMMARY_EMAIL, // ccRecipient
      //     attachmentMessage,
      //     `${response.message.orderNo}.pdf`
      //   );
      // } else {
      //   await microsoftSendEmail(
      //     title,
      //     emailMessage,
      //     userAuthEmail,
      //     microsoftGraphToken,
      //     process.env.REACT_APP_MICROSOFT_PURCHASE_ORDER_SUMMARY_EMAIL, // ccRecipient
      //     attachmentMessage,
      //     `${response.message.orderNo}.pdf`
      //   );
      // }

      // if warehouse_id == 1:
      let planId = orderState.warehouse_details.microsoft_planner.plan_id;
      let bucketId = orderState.warehouse_details.microsoft_planner.bucket_id;
      // let assigneeId = orderState.user_id;
      let assigneeGroupId =
        orderState.warehouse_details.microsoft_planner.assignee_group_id;
      logtail.info(
        `Trigger microsoftCreatePlannerTask if typeOrder === TYPE_ORDER.SALE typeOrder: ${typeOrder}`
      );
      if (typeOrder === TYPE_ORDER.SALE) {
        await microsoftCreatePlannerTask(
          title,
          planId,
          bucketId,
          userAuthId,
          deliveryDate,
          emailMessage,
          microsoftGraphToken,
          assigneeGroupId
        );
      }
      let teamsMessage = `<pre>${emailMessage}</pre>`;
      let teamId = process.env.REACT_APP_MICROSOFT_SALES_TEAM_ID;
      let channelId = process.env.REACT_APP_MICROSOFT_SALES_CHANNEL_ID;
      await microsoftSendTeamsMessage(
        title,
        teamsMessage,
        "html",
        teamId,
        channelId,
        microsoftGraphToken
      );
    });
  } else {
    let slackMessage = JSON.stringify(
      {
        status_code: response.status,
        status_message: response.statusText,
        request_url: response.url,
        request_body: raw,
      },
      null,
      2
    );
    // send fail message for slack channel
    await sendMessageToSlack(
      "```".concat(slackMessage, "```"),
      process.env.REACT_APP_SLACK_TENANT_ID,
      process.env.REACT_APP_SLACK_CHANNEL_ID
    );
  }

  return {
    status: response.status,
    purchaseNo: purchaseNoResponseValue,
    supplierNo: supplierNoResponseValue,
  };
}

export const TYPE_ORDER = {
  SALE: "sale",
  PURCHASE: "purchase",
};
