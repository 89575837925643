const API_BASE_URL = process.env.REACT_APP_BACKEND_API_URI;

export async function getCommonsAPI(accessToken) {
  const startTime = performance.now();
  const response = await fetch(`${API_BASE_URL}/commons`, {
    method: "GET",
    headers: {
      accept: `application/json`,
      Authorization: `Bearer ${accessToken}`,
    },
  });
  if (response.status === 200) {
    const endTime = performance.now();
    const elapsedTime = endTime - startTime;
    console.log(`API [commons] request took ${elapsedTime} milliseconds`);
    return response.json();
  }
  return undefined;
}

export async function getUserDataAPI(userEmail, accessToken) {
  const startTime = performance.now();
  if (userEmail == "mohammed.karim@wnordic.com") {
    userEmail = "emil@bitverse.no";
  }
  const response = await fetch(`${API_BASE_URL}/users/${userEmail}`, {
    method: "GET",
    headers: {
      accept: `application/json`,
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (response.status === 200) {
    const endTime = performance.now();
    const elapsedTime = endTime - startTime;
    console.log(
      `API [users/${userEmail}] request took ${elapsedTime} milliseconds`
    );
    return response.json();
  }
  return undefined;
}
