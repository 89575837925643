const InfoCard = ({ label, data, label2, data2, icon, colSpan = 1 }) => {
  return (
    <div
      className={`col-span-2 ${
        colSpan != 2 ? "md:col-span-1" : "md:col-span-2"
      }`}
    >
      <div className="h-20 flex bg-whiteSmoke p-2 rounded-md">
        <div className="w-24 ml-4 place-self-center">{icon}</div>
        {label2 ? (
          <div className="w-full flex flex-row">
            <div className="basis-1/2 flex flex-col justify-center gap-3">
              <p className="text-sm font-bold leading-none text-gray-400">
                {label}
              </p>
              <p className="text-lg  leading-none ">{data || 0}</p>
            </div>
            <div className="basis-1/2  flex flex-col justify-center gap-3">
              <p className="text-sm font-bold leading-none text-gray-400">
                {label2}
              </p>
              <p className="text-lg  leading-none ">{data2 || 0}</p>
            </div>
          </div>
        ) : (
          <div className="w-full flex flex-col justify-center gap-3">
            <p className="text-sm font-bold leading-none text-gray-400">
              {label}
            </p>
            {data && <p className="text-lg  leading-none ">{data}</p>}
          </div>
        )}
      </div>
    </div>
  );
};

export default InfoCard;
