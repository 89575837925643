import React, { useState, useContext } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Riple } from "react-loading-indicators";
import { useMsal } from "@azure/msal-react";


import { TYPE_ORDER, orderAPI } from "../../api/order_api";
import { userDataContext } from "../../context/userDataContext";
import SaleOrderHeadingSection from "./components/SaleOrderHeadingSection";
import SaleProductLineSection from "./components/SaleProductLineSection";
import { SALE_ACTIONS, saleOrderContext } from "../../context/saleOrderContext";
import {
  useMicrosoftCustomToken,
} from "../../utils/useMicrosoftGraphToken";
import InfoCard from "../../components/ui/InfoCard";
import ShowSubmitOrderModel from "../../components/ui/showSubmitOrderModel";
import { ReactComponent as DiamondIcon } from "../../assets/icons/Diamond.svg";
import { CurrencyDollarIcon } from "@heroicons/react/24/solid";

import "../../style/common.css";
import "react-toastify/dist/ReactToastify.css";

function SaleOrderPage() {

  const microsoftCustomToken = useMicrosoftCustomToken();
  const { userDataState } = useContext(userDataContext);
  const { saleOrderState, dispatchSaleOrder } = useContext(saleOrderContext);

  const [postOrderLoading, setOrderPostLoading] = useState(false);
  const [showModel, setShowModel] = useState(false);
  const [amountDomesticWaringModelState, setAmountDomesticWaringModelState] =
    useState(0);

  // get user token after every 1 hour
  const { instance, accounts } = useMsal();
  const createMicrosoftGraphToken = async () => {
    const response = await instance.acquireTokenSilent({
      scopes: [`User.Read ChannelMessage.Send Tasks.ReadWrite Mail.Send GroupMember.Read.All`],
      account: accounts[0]
    });

    return response.accessToken;
  }

  const totalProductLinesAmount = () =>
    saleOrderState.product_lines.reduce(
      (sum, { price, quantity }) => sum + price * quantity,
      0
    );

  const handleSubmit = async (e, pass) => {
    e.preventDefault();

    if (!saleOrderState.customer_details) {
      toast.info("Vennligst velg enten fartøy eller kunde");
      return;
    }

    // check if show model ore not
    if (pass !== "pass") {
      var totalOrderPrice =
        totalProductLinesAmount() + saleOrderState.domestic_amount;
      if (totalOrderPrice > saleOrderState.customer_details.creditLimit) {
        setShowModel(true);
        setAmountDomesticWaringModelState(
          totalOrderPrice - saleOrderState.customer_details.creditLimit
        );
        return;
      }
    }

    // close model if model was open
    setShowModel(false);


    setOrderPostLoading(true);
    const microsoftGraphToken = await createMicrosoftGraphToken();

    const res = await orderAPI(
      saleOrderState,
      microsoftGraphToken,
      microsoftCustomToken,
      userDataState.account.username,
      userDataState.account.localAccountId,
      userDataState.account.name,
      TYPE_ORDER.SALE,

    );
    setOrderPostLoading(false);

    if (res.status == 200) {
      toast.success("Ordre opprettet");
      // reset form after submit and if response status is 200
      if (process.env.NODE_ENV === "production") {
        document.getElementById("saleOrderForm").reset();
        dispatchSaleOrder({
          type: SALE_ACTIONS.RESET_FORM,
        });
      }
    } else if (res.status == 500) {
      toast.error("Ordre mislykkes (500 - Internal server error)");
    } else {
      toast.error("Ordre mislykkes");
    }
  };

  return (
    <>
      <ToastContainer autoClose={2000} />
      <main>
        <div className="mx-4 sm:mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
          {postOrderLoading && (
            <div className="loading flex items-center justify-center">
              <Riple color="#04c3ff" size="medium" text="" textColor="" />
            </div>
          )}
          <form id="saleOrderForm" onSubmit={handleSubmit}>
            <SaleOrderHeadingSection />
            <SaleProductLineSection />
            <div className="pt-14 pb-8">
              <InfoCard
                label="Totalt beløp"
                data={
                  totalProductLinesAmount()?.toLocaleString("nb-NO", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }) || "0.0"
                }
                icon={<CurrencyDollarIcon className="w-12 text-deepSkyBlue" />}
              />
            </div>
            <button
              type="submit"
              className="w-full text-white bg-deepSkyBlue hover:bg-deepSkyBlue/90 focus:ring-4 focus:outline-none focus:ring-deepSkyBlue/50 rounded-md text-xl py-2 text-center flex items-center justify-center gap-2"
            >
              <DiamondIcon />
              Opprett salgsordre
              <DiamondIcon />
            </button>
          </form>
        </div>
      </main>
      <ShowSubmitOrderModel
        open={showModel}
        setOpen={setShowModel}
        handleSubmit={handleSubmit}
        content={`Du er nå ${amountDomesticWaringModelState.toLocaleString("nb-NO", {
          maximumFractionDigits: 0,
        }) || ""
          } krone${amountDomesticWaringModelState > 1 ? "r" : ""} 
          over grense, skal salget gjennomføres likevel?`}
      />
    </>
  );
}

export default SaleOrderPage;
