import React, { createContext, useReducer } from "react";
import { SALE_ACTIONS } from "./saleOrderContext";

// create purchase order context
export const purchaseOrderContext = createContext();

// create function for reducer
const PurchaseOrderReducer = (state, action) => {
  switch (action.type) {
    case PURCHASE_ACTIONS.SET_TRADE_DEFAULT_VALUE:
      state.purchase_object.order_type = 1;
      state.purchase_object.group_6 = 8;
      return { ...state };
    case PURCHASE_ACTIONS.ADD_PRODUCT_LINE:
      state.product_lines = [...state.product_lines, action.data];
      return { ...state };
    case PURCHASE_ACTIONS.UPDATE_PRODUCT_LINE:
      state.product_lines[action.data.index] = action.data.productLine;
      return { ...state };
    case PURCHASE_ACTIONS.DELETE_PRODUCT_LINE:
      state.product_lines.splice(action.data, 1);
      return { ...state };
    case PURCHASE_ACTIONS.DUPLICATE_SALE_PRODUCT_LINE:
      // remove first item in list if not fill
      if (!state.product_lines[0].productNo) {
        state.product_lines.splice(action.data, 1);
      }
      action.data.quantity = 0;
      action.data.price = 0;
      state.product_lines = [...state.product_lines, action.data];
      return { ...state };
    case PURCHASE_ACTIONS.UPDATE_DUPLICATE_SALE_PRODUCT_LINE:
      const productIdIndex = state.product_lines.findIndex(
        (product) => product?.lineId == action.data.lineId
      );
      // disable price updating
      action.data.price = state.product_lines[productIdIndex].price;
      // update product line
      state.product_lines[productIdIndex] = action.data;
      return { ...state };
    case PURCHASE_ACTIONS.DOMESTIC_AMOUNT:
      state.domestic_amount = action.data;
      return { ...state };
    case PURCHASE_ACTIONS.USER_ID:
      state.user_id = action.data;
      return { ...state };
    case PURCHASE_ACTIONS.USER_EMAIL:
      state.user_email = action.data;
      return { ...state };
    // RELATE PURCHASE
    case PURCHASE_ACTIONS.SUPPLIER_NUMBER:
      // set customer info
      state.supplier_details = action.data;
      // set supplier id for supplier object
      state.purchase_object.supplier_number = action.data.supplierId;
      state.purchase_object.supplier_name = action.data.supplierName;
      // set default value for payment_terms & currency_number
      if (action.data.paymentTermId) {
        state.purchase_object.payment_terms = action.data.paymentTermId;
      }
      state.purchase_object.currency_number = action.currency_number;
      return { ...state };
    case PURCHASE_ACTIONS.OUR_REFERENCE:
      // state.purchase_object.our_reference = action.data.toUpperCase();
      return { ...state };
    case PURCHASE_ACTIONS.YOUR_REFERENCE:
      state.purchase_object.your_reference = action.data.toUpperCase();
      return { ...state };
    case PURCHASE_ACTIONS.EMPLOYEE_ID:
      // set employee id for employee_number and seller_or_buyer
      state.purchase_object.employee_number = action.data;
      state.purchase_object.seller_or_buyer = action.data;
      return { ...state };
    case PURCHASE_ACTIONS.PAYMENT_TERMS:
      state.purchase_object.payment_terms = action.data;
      return { ...state };
    case PURCHASE_ACTIONS.WAREHOUSE:
      // warehouse_details contain planner data also warehouse info
      state.warehouse_details = action.data;
      state.purchase_object.warehouse_number = action.data.warehouse_id;
      return { ...state };
    case PURCHASE_ACTIONS.DELIVERY_DATE:
      state.purchase_object.delivery_date = action.data.toISOString();
      return { ...state };
    case PURCHASE_ACTIONS.CURRENCY_NUMBER:
      state.purchase_object.currency_number = action.data;
      return { ...state };
    case PURCHASE_ACTIONS.NOTE:
      state.note = action.data;
      return { ...state };
    case PURCHASE_ACTIONS.DELIVERY_ADDRESS:
      state.purchase_object.delivery_address = action.data;
      return { ...state };
    case PURCHASE_ACTIONS.DELIVERY_METHOD:
      state.purchase_object.delivery_method = action.data;
      return { ...state };
    case PURCHASE_ACTIONS.INFORMATION_5:
      state.purchase_object.information_5 = action.data.toUpperCase();
      return { ...state };
    case PURCHASE_ACTIONS.INFORMATION_6:
      state.purchase_object.information_6 = action.data.toUpperCase();
      return { ...state };
    case PURCHASE_ACTIONS.EXEMPT_FROM_SPECIAL_TAX:
      state.purchase_object.exempt_from_special_tax = action.data;
    case PURCHASE_ACTIONS.TAX_CODE:
      state.purchase_object.tax_code = action.data;
      return { ...state };
    case PURCHASE_ACTIONS.CUSTOMER_OR_SUPPLIER_ORDER_NUMBER:
      state.purchase_object.customer_or_supplier_order_number =
        action.data.toUpperCase();
      return { ...state };
    // reset form
    case PURCHASE_ACTIONS.RESET_FORM:
      let newState = {
        reset_form_state: !state.reset_form_state,
        domestic_amount: 0,
        document_type: 5,
        product_lines: [
          {
            productNo: "",
            name: "",
            quantity: 0,
            price: 0,
            unit: "",
            unitType: "",
            description: null,
            discountPercent: 0,
            discountAmountInCurrency: 0,
          },
        ],
        purchase_object: {
          order_type: 1,
          transaction_type: 6,
          invoice_id: "PRELIM",
          employee_number: state.purchase_object.employee_number,
          seller_or_buyer: state.purchase_object.seller_or_buyer,
          our_reference: state.purchase_object.our_reference,
          warehouse_number: 1,
          external_order_id: null,
          delivery_date: null,
          exempt_from_special_tax: 0,
          tax_code: 1,
          customer_or_supplier_order_number: null,
          currency_number: 47,
          payment_terms: 21,
        },
      };
      return { ...newState };
    default:
      return { ...state };
  }
};

// initial value for reducer
const initialState = {
  reset_form_state: false,
  domestic_amount: 0,
  document_type: 5,
  product_lines: [
    {
      productNo: "",
      name: "",
      quantity: 0,
      price: 0,
      unit: "",
      unitType: "",
      description: null,
      discountPercent: 0,
      discountAmountInCurrency: 0,
    },
  ],
  purchase_object: {
    order_type: 1,
    transaction_type: 6,
    invoice_id: "PRELIM",
    tax_code: 1,
    customer_or_supplier_order_number: null,
    exempt_from_special_tax: 0,
    warehouse_number: 1,
    external_order_id: null,
    delivery_date: null,
    currency_number: 47,
    payment_terms: 21,
  },
};

// pass data around tree
const PurchaseOrderContextProvider = (props) => {
  const [purchaseOrderState, dispatchPurchaseOrder] = useReducer(
    PurchaseOrderReducer,
    initialState
  );

  return (
    <purchaseOrderContext.Provider
      value={{ purchaseOrderState, dispatchPurchaseOrder }}
    >
      {props.children}
    </purchaseOrderContext.Provider>
  );
};

export default PurchaseOrderContextProvider;

export const PURCHASE_ACTIONS = {
  SET_TRADE_DEFAULT_VALUE: "set_trade_default_value",
  ADD_PRODUCT_LINE: "add_product_line",
  UPDATE_PRODUCT_LINE: "update_product_line",
  DELETE_PRODUCT_LINE: "delete_product_line",
  DOMESTIC_AMOUNT: "domestic_amount",
  USER_ID: "user_id",
  USER_EMAIL: "user_email",
  RESET_FORM: "reset_form",
  DUPLICATE_SALE_PRODUCT_LINE: "duplicate_sale_product_line",
  UPDATE_DUPLICATE_SALE_PRODUCT_LINE: "update_duplicate_sale_product_line",

  // RELATE PURCHASE
  OUR_REFERENCE: "our_reference",
  YOUR_REFERENCE: "your_reference",
  EMPLOYEE_ID: "employee_id",
  PAYMENT_TERMS: "payment_terms",
  WAREHOUSE: "warehouse",
  DELIVERY_DATE: "delivery_date",
  CURRENCY_NUMBER: "currency_number",
  EXEMPT_FROM_SPECIAL_TAX: "exempt_from_special_tax",
  TAX_CODE: "tax_code",
  NOTE: "note",
  CUSTOMER_OR_SUPPLIER_ORDER_NUMBER: "customer_or_supplier_order_number",
  SUPPLIER_NUMBER: "supplier_number",
  DELIVERY_ADDRESS: "delivery_address",
  DELIVERY_METHOD: "delivery_method",
  INFORMATION_5: "information_5",
  INFORMATION_6: "information_6",
};
