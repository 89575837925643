import React, { createContext, useState } from "react";

// create user data context
export const userDataContext = createContext();

// pass data around tree
const UserDataContextProvider = (props) => {
  const [userDataState, dispatchUserData] = useState();

  return (
    <userDataContext.Provider value={{ userDataState, dispatchUserData }}>
      {props.children}
    </userDataContext.Provider>
  );
};

export default UserDataContextProvider;
