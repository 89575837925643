import React from "react";
import ReactDOM from "react-dom/client";
import App from "./router/App";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";

import CommonAPIContextProvider from "./context/commonAPIContext";
import UserDataContextProvider from "./context/userDataContext";
import SaleOrderContextProvider from "./context/saleOrderContext";
import PurchaseOrderContextProvider from "./context/purchaseOrderContext";

import "./style/index.css";

const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <MsalProvider instance={msalInstance}>
    <UserDataContextProvider>
      <CommonAPIContextProvider>
        <SaleOrderContextProvider>
          <PurchaseOrderContextProvider>
            <App />
          </PurchaseOrderContextProvider>
        </SaleOrderContextProvider>
      </CommonAPIContextProvider>
    </UserDataContextProvider>
  </MsalProvider>
);
