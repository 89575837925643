export async function downloadBlob(blob, name = "fil.txt") {
  const blobUrl = URL.createObjectURL(blob);

  const link = document.createElement("a");

  link.href = blobUrl;
  link.download = name;
  document.body.appendChild(link);
  link.dispatchEvent(
    new MouseEvent("click", {
      bubbles: true,
      cancelable: false,
      view: window,
    })
  );
  document.body.removeChild(link);
}
