import React from "react";
import Select from "react-select";

import { SingleValue, Option } from "../../utils/selectStyle";

const SearchDropDownField = ({
  label,
  options,
  onChange,
  value,
  placeholder,
}) => {
  const style = {
    control: (base, state) => ({
      ...base,
      backgroundColor: "#f2f3f9",
      padding: "0.300rem",
      border: state.isFocused ? 0 : 0,
    }),
  };

  return (
    <div className={`mb-2 col-span-2 sm:col-span-2`}>
      <label className="block text-md font-light leading-6 text-gray-900">
        {label}
      </label>
      <div className="mt-2 ">
        <Select
          styles={style}
          options={options}
          value={value}
          components={{
            SingleValue,
            Option,
            NoOptionsMessage: () => "Ingen treff...",
          }}
          getOptionLabel={(loadOptions) => {
            return `${loadOptions.title} ${loadOptions.subTitle}`;
          }}
          onChange={onChange}
          placeholder={placeholder}
          className="block w-full rounded-md border-0 p-1 bg-whiteSmoke text-gray-900 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6"
        />
      </div>
    </div>
  );
};

export default SearchDropDownField;
