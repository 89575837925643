import React, { useState, useEffect, useContext } from "react";
import { Riple } from "react-loading-indicators";

import PurchaseProductLineCard from "./PurchaseProductLineCard";
import FreeTextLineButton from "../../../components/ui/FreeTextLineButton";
import {
  PURCHASE_ACTIONS,
  purchaseOrderContext,
} from "../../../context/purchaseOrderContext";
import { userDataContext } from "../../../context/userDataContext";

import { getProductsAPI } from "../../../api/order_api";

function PurchaseProductLineSection() {
  const { userDataState } = useContext(userDataContext);
  const { purchaseOrderState, dispatchPurchaseOrder } =
    useContext(purchaseOrderContext);

  const [productLoading, setProductLoading] = useState(false);
  const [productOptions, setProductOptions] = useState([]);

  // get product form api
  const getProducts = async (productName = "") => {
    let res = await getProductsAPI(productName, userDataState.accessToken);

    let arr = res.map((product) => ({
      value: {
        productId: product.product_id,
        name: (product.name || "").trim(),
        unitType: product?.unit_type,
        unit: product?.unit || "",
      },
      title: product.name,
      subTitle: product.unit_type || "",
    }));

    setProductOptions(arr);

    return arr;
  };

  // get product when page build
  const getProductOptionsWhenPageBuild = async () => {
    if (productOptions) {
      setProductLoading(true);
      await getProducts();
      setProductLoading(false);
    }
  };

  useEffect(() => {
    getProductOptionsWhenPageBuild();
  }, []);

  if (productLoading) {
    return (
      <div className="loading-indicator ">
        <Riple color="#04c3ff" size="medium" text="" textColor="" />
      </div>
    );
  }

  return (
    <>
      <div className="border-[1px] border-deepSkyBlue rounded-2xl mt-8">
        <div className="grid grid-cols-2 gap-4 p-10">
          <p className="col-span-full text-deepSkyBlue text-2xl mb-6">
            Varelinjer
          </p>
          {purchaseOrderState.product_lines.map((productLine, index) => {
            return (
              <PurchaseProductLineCard
                key={index}
                index={index}
                productOptions={productOptions}
                getProducts={getProducts}
                productLine={productLine}
              />
            );
          })}
        </div>
        <div className="col-span-2 p-4 bg-whiteSmoke rounded-b-2xl">
          <div className="flex flex-row justify-between items-center">
            <button
              onClick={(e) => {
                dispatchPurchaseOrder({
                  type: PURCHASE_ACTIONS.ADD_PRODUCT_LINE,
                  data: {
                    productNo: null,
                    name: "",
                    quantity: 0,
                    price: 0,
                    unit: "",
                    unitType: "",
                    discountPercent: 0,
                    discountAmountInCurrency: 0,
                  },
                });
              }}
              type="button"
              className="rounded-sm bg-deepSkyBlue hover:ring-deepSkyBlue/50 text-white px-4 py-2 focus:outline-none focus:ring-2 focus:ring-deepSkyBlue/50"
            >
              + Ny varelinje
            </button>
            {window.location.pathname === "/purchase" && (
              <FreeTextLineButton
                onClick={(e) => {
                  dispatchPurchaseOrder({
                    type: PURCHASE_ACTIONS.ADD_PRODUCT_LINE,
                    data: {
                      productNo: 7,
                      quantity: 0,
                      price: 0,
                      description: "",
                      unit: "hide",
                      unitType: "",
                    },
                  });
                }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
export default PurchaseProductLineSection;
